<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <Mobile v-if="Object.keys(currentPosition).length > 0"
            class="mobile py-16"
            :datas="datas"
            :process="process"
            :selected="selected"
            :menu="menu"
            :center="center"
            :currentPosition="currentPosition" />

          <Desktop v-if="Object.keys(currentPosition).length > 0"
            class="desktop pt-16"
            :datas="datas"
            :process="process"
            :selected="selected"
            :menu="menu"
            :center="center"
            :currentPosition="currentPosition" />
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        selected: {
          banner: 0,
          venue: 0,
          gallery: 0,
          testimony: 0
        },
        menu: [
          {
            title: 'Menu Resto',
            icon: require('@/assets/icon/WheelChair 1.png')
          },
          {
            title: 'Wheelchair Access',
            icon: require('@/assets/icon/WheelChair 2.png')
          },
          {
            title: 'Musholla',
            icon: require('@/assets/icon/WheelChair 3.png')
          },
          {
            title: 'Souvenir Shop',
            icon: require('@/assets/icon/WheelChair 4.png')
          },
          {
            title: 'Parking Area',
            icon: require('@/assets/icon/WheelChair 5.png')
          }
        ],
        center: {
          lat: -7.849519591217892,
          lng: 110.47830746200785
        },
        currentPosition: {},
        datas: {
          banners: [],
          venues: [],
          galleries: [],
          testimonies: []
        },
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'Beranda HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Mobile: () => import(/* webpackPrefetch: true */ '@/components/mobile/home.vue'),
      Desktop: () => import(/* webpackPrefetch: true */ '@/components/desktop/home.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchHome()

        this.$gmapApiPromiseLazy().then(() => {
          this.currentPosition = {
            latlng: {
              lat: -7.849519591217892,
              lng: 110.47830746200785
            },
            icon: {
              url: require('@/assets/image/location_pin.png'),
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(25, 50)
            }
          }
        })
      },
      async fetchHome () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/home`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.datas = response.result
          } 
        })
      }
    }
  }
</script>