<template>
  <div>
    <v-container>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="text-center">
          <v-carousel 
            v-model="selected.banner"
            height="450"
            hide-delimiters
            show-arrows-on-hover
            style="border-radius: 15px;">
            <v-carousel-item 
              v-for="(item, index) in process.load ? 6 : datas.banners" 
              :key="index" 
              reverse-transition="fade-transition"
              transition="fade-transition">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.load"
                type="image, image, image"
                style="border-radius: 0px;">
                <div>
                  <v-img v-if="!process.laod && datas.banners.length > 0"
                    width="100%"
                    height="450"
                    alt="heha" 
                    gradient="to top, rgba(0, 0, 0, .1), rgba(0, 0, 0, .7)"
                    :src="item.banner_image"
                    class="ma-auto d-flex align-center">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <v-row>
                      <v-col
                        cols="8">
                        <div
                          class="white--text text-left pa-16">
                          <h1
                            class="text-h3 font-weight-bold mb-2"
                            v-html="item.banner_title.replace(/(<([^>]+)>)/ig, '')">
                          </h1>

                          <h2
                            class="text-h3 font-weight-bold">
                            in <span class="color-default">Yogyakarta.</span>
                          </h2>

                          <h3
                            class="title font-weight-light line-text-second my-8"
                            v-html="item.banner_description.replace(/(<([^>]+)>)/ig, '')">
                          </h3>

                          <v-btn
                            to="/reservation"
                            x-large
                            color="#F38C35"
                            class="body-1 text-capitalize white--text border-radius font-weight-bold"
                            style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;">
                            Reservasi Tiket Sekarang
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-img>
                </div>
              </v-skeleton-loader>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-text
          class="d-flex align-center pa-0 py-16 mb-4">
          <no-ssr>
            <swiper 
              class="swiper"
              ref="swiper"
              :options="{ slidesPerView: 'auto' }">
              <swiper-slide 
                v-for="(item, index) in menu" 
                :key="index" 
                style="width: 210px; padding: 10px;">
                <v-card
                  class="a_menu"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                        border-radius: 15px;">
                  <div>
                    <v-list
                      color="transparent">
                      <v-list-item>
                        <v-list-item-avatar
                          size="60"
                          color="#F0F3F9"
                          class="border-radius">
                          <v-img 
                            contain
                            width="36"
                            height="36"
                            alt="heha" 
                            :src="item.icon"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  color="#F38C35">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <div
                            class="body-2 two-line line-text-first color-purple a_text">
                            {{ item.title }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-card>
              </swiper-slide>
            </swiper>
          </no-ssr>
        </v-card-text>
      </v-card>
    </v-container>

    <v-card
      flat
      color="#FFFFFF"
      style="border-radius: 0px;">
      <v-card-text
        class="text-center">
        <v-container>
          <div
            class="pa-16">
            <div
              class="text-h5 font-weight-bold color-purple mb-8">
              HeHa Sky View
            </div>

            <div
              class="body-1 line-text-second black--text px-16">
              Salah satu destinasi wisata favorit yang menawarkan pemandangan terbaik kota Yogyakarta dan sekitarnya “dari lantai 2”. Terletak di kawasan perbukitan Gunungkidul, HeHa Sky View hanya berjarak 40 menit dari pusat Kota Yogyakarta.
            </div>
          </div>
        </v-container>
      </v-card-text>

      <div
        class="map_desktop"
        style="width:100%; height: 600px;">
        <gmap-map
          ref="gmap"
          :center="center"
          :zoom="16"
          :scrollwheel="false"
          style="width:100%; height: 600px;">
          <gmap-marker
            :position="currentPosition.latlng"
            :icon="currentPosition.icon">
          </gmap-marker>
        </gmap-map>

        <v-container
          style="position: absolute; bottom: 10px; left: 0; right: 0;">
          <v-row>
            <v-col
              cols="4">
              <v-card
                height="175"
                color="#FFFFFF"
                class="pa-4 py-8"
                style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; border-radius: 15px;">
                <v-img 
                  contain
                  width="100%"
                  height="36"
                  alt="heha" 
                  :src="require('@/assets/icon/marker.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 black--text mt-2 text-center three-line line-text-first mt-6">
                  Jl. Dlingo-Patuk No.2, Bukit, Patuk, Gunung Kidul Regency, Special Region Of Yogyakarta 55862
                </div>
              </v-card>
            </v-col>

            <v-col
              cols="4">
              <v-card
                height="175"
                color="#FFFFFF"
                class="pa-4 py-8"
                style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; border-radius: 15px;">
                <v-img 
                  contain
                  width="100%"
                  height="36"
                  alt="heha" 
                  :src="require('@/assets/icon/call_color.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 black--text mt-2 text-center three-line line-text-first mt-6">
                  0812-1700-4900
                </div>
              </v-card>
            </v-col>

            <v-col
              cols="4">
              <v-card
                height="175"
                color="#FFFFFF"
                class="pa-4 py-8"
                style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; border-radius: 15px;">
                <v-img 
                  contain
                  width="100%"
                  height="36"
                  alt="heha" 
                  :src="require('@/assets/icon/email.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 black--text mt-2 text-center three-line line-text-first mt-6">
                  hehaskyview@gmail.com
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card>

    <v-card
      flat
      color="#FFFFFF"
      style="border-radius: 0px;">
      <v-container>
        <div
          class="py-16">
          <div
            class="text-h5 font-weight-bold color-purple text-center mb-8">
            Venue
          </div>

          <no-ssr>
            <carousel-3d 
              ref="carousel3dVenue"
              :autoplay="false"
              :autoplay-timeout="5000"
              :perspective="0"
              :border="0"
              :space="0"
              :width="1200"
              :height="400"
              :disable3d="true"
              :count="datas.venues.length"
              :display="1"
              @after-slide-change="onAfterSlideChangeVenue">
              <slide 
                v-for="(item, index) in process.load ? 6 : datas.venues" 
                :key="index" 
                :index="index"
                style="background: none; padding: 20px;">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.load"
                  type="image, image"
                  style="border-radius: 0px;">
                  <div>
                    <v-card v-if="!process.laod && datas.venues.length > 0"
                      class="pa-6"
                      style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                          border-radius: 15px;">
                      <v-row
                        align="center">
                        <v-col
                          cols="6"
                          class="py-0">
                          <v-img 
                            width="100%"
                            height="300"
                            alt="heha" 
                            :src="item.venue_image"
                            class="ma-auto d-flex align-center"
                            style="border-radius: 15px;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#F38C35">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>

                        <v-col
                          cols="6"
                          class="py-0">
                          <div
                            class="text-h5 font-weight-bold black--text mb-6">
                            {{ item.venue_title }}
                          </div>

                          <div
                            class="body-1 black--text line-text-second">
                            {{ item.venue_description }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </slide>
            </carousel-3d>
          </no-ssr>

          <div 
            class="text-center mt-6"> 
            <v-btn 
              v-for="(item, index) in datas.venues" 
              :key="index"
              min-width="36"
              height="12"
              elevation="0"
              rounded
              :color="selected.venue === index ? '#2B3F6C' : '#F0F3F9'"
              class="mx-2"
              @click="goToSlideVenue(index)">
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>

    <v-container>
      <div
        class="py-16">
        <div
          class="text-h5 font-weight-bold color-purple text-center mb-8">
          Galeri
        </div>

        <no-ssr>
          <carousel-3d 
            ref="carousel3dGallery"
            :autoplay="false"
            :autoplay-timeout="5000"
            :perspective="200"
            :border="0"
            :space="350"
            :width="350"
            :height="350"
            :disable3d="false"
            :count="datas.galleries.length"
            :display="5"
            @after-slide-change="onAfterSlideChangeGallery">
            <slide 
              v-for="(item, index) in process.load ? 6 : datas.galleries" 
              :key="index"
              :index="index"
              style="background: none; padding: 20px;">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.load"
                type="image, image"
                style="border-radius: 0px;">
                <div>
                  <v-img v-if="!process.laod && datas.galleries.length > 0"
                    width="300"
                    height="300"
                    alt="heha" 
                    :src="item.gallery_image"
                    class="ma-auto d-flex align-center"
                    style="border-radius: 15px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-skeleton-loader>
            </slide>
          </carousel-3d>
        </no-ssr>

        <div 
          class="text-center mt-6"> 
          <v-btn 
            v-for="(item, index) in datas.galleries" 
            :key="index"
            min-width="36"
            height="12"
            elevation="0"
            rounded
            :color="selected.gallery === index ? '#2B3F6C' : '#F0F3F9'"
            class="mx-2"
            @click="goToSlideGallery(index)">
          </v-btn>
        </div>

        <div
          class="text-center mt-12">
          <v-btn
            to="/gallery"
            large
            outlined
            color="#F38C35"
            class="body-1 text-capitalize color-default border-radius font-weight-bold">
            Selengkapnya
          </v-btn>
        </div>
      </div>
    </v-container>

    <v-card
      flat
      color="#FFFFFF"
      style="border-radius: 0px;">
      <v-container>
        <div
          class="pt-16">
          <div
            class="text-h5 font-weight-bold color-purple text-center mb-8">
            Testimoni
          </div>
          
          <no-ssr>
            <carousel-3d 
              ref="carousel3dTestimony"
              :autoplay="false"
              :autoplay-timeout="5000"
              :perspective="0"
              :border="0"
              :space="375"
              :width="400"
              :height="300"
              :disable3d="true"
              :count="datas.testimonies.length"
              :display="5"
              @after-slide-change="onAfterSlideChangeTestimony">
              <slide 
                v-for="(item, index) in process.load ? 6 : datas.testimonies" 
                :key="index"
                :index="index"
                style="background: none; padding: 20px;">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.load"
                  type="image, image"
                  style="border-radius: 0px;">
                  <div>
                    <v-card v-if="!process.laod && datas.testimonies.length > 0"
                      flat
                      class="pa-6"
                      color="#F0F3F9"
                      style="border-radius: 15px;">
                      <v-card-text
                        class="pa-3">
                        <v-list 
                          flat 
                          color="transparent"
                          class="pt-1 pb-3">
                          <v-list-item
                            class="pa-0">
                            <v-list-item-avatar
                              color="#FFFFFF"
                              size="50"
                              class="border-radius my-0">
                              <v-img 
                                contain
                                width="35"
                                height="35"
                                alt="heha" 
                                :src="item.testimony_user_image === '' ? require('@/assets/icon/user.png') : item.testimony_user_image"
                                class="ma-auto"
                                style="border-radius: 10px;">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular 
                                      indeterminate 
                                      color="#F38C35">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content
                              class="pa-0">
                              <v-list-item-title 
                                class="body-1 font-weight-bold mb-2">
                                {{ item.testimony_user_name }}
                              </v-list-item-title>

                              <v-list-item-subtitle>
                                {{ item.testimony_date | date }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>

                        <div
                          class="body-2 line-text-first five-line mt-1"
                          style="max-width: 277px;">
                          "{{ item.testimony_content }}"
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </slide>
            </carousel-3d>
          </no-ssr>

          <div 
            class="text-center mt-6 pb-14"> 
            <div
              class="pb-16">
              <v-btn 
                v-for="(item, index) in datas.testimonies" 
                :key="index"
                min-width="36"
                height="12"
                elevation="0"
                rounded
                :color="selected.testimony === index ? '#2B3F6C' : '#F0F3F9'"
                class="mx-2"
                @click="goToSlideTestimony(index)">
              </v-btn>
            </div>
          </div>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      selected: {
        type: Object,
        default: {}
      },
      datas: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      },
      menu: {
        type: Array,
        default: []
      },
      center: {
        type: Object,
        default: {}
      },
      currentPosition: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      onAfterSlideChangeVenue(index) {
        this.selected.venue = index
      },
      goToSlideVenue(index) {
        this.selected.venue = index
        this.$refs.carousel3dVenue.goSlide(index)
      },

      onAfterSlideChangeGallery(index) {
        this.selected.gallery = index
      },
      goToSlideGallery(index) {
        this.selected.gallery = index
        this.$refs.carousel3dGallery.goSlide(index)
      },

      onAfterSlideChangeTestimony(index) {
        this.selected.testimony = index
      },
      goToSlideTestimony(index) {
        this.selected.testimony = index
        this.$refs.carousel3dTestimony.goSlide(index)
      }
    }
  }
</script>

<style scoped>
  .a_menu:hover {
    background: #F38C35;
    border-radius: 10px;
  }

  .a_menu:hover .v-avatar {
    background: #FFFFFF !important;
  }

  .a_menu:hover .a_text {
    font-weight: bold;
    color: #FFFFFF !important;
  }
</style>