<template>
  <div>
    <v-card
      flat
      color="transparent">
      <v-card-title
        class="d-flex align-center pa-3"
        style="position: fixed;
              top: 100 px;
              width: 460px;
              background: #f9f9f9;
              z-index: 2;">
        <v-btn
          @click="$router.go(-1)"
          icon 
          width="36"
          height="36">
          <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
        </v-btn>

        <span
          class="body-1 font-weight-bold color-purple ml-2">
          Galeri
        </span>
      </v-card-title>

      <v-card-text
        class="pt-16">
        <v-expansion-panels 
          v-model="selected.gallery"
          focusable
          flat
          class="pt-4">
          <v-expansion-panel
            v-for="(item, index) in gallery"
            :key="index"
            style="background-color: transparent;">
            <v-expansion-panel-header
              :color="selected.gallery === index ? '#F38C35' : 'white'"
              :class="selected.gallery === index ? 'white--text' : 'color-purple'"
              class="body-1 font-weight-bold mb-4"
              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
              {{ item.title }}

              <template v-slot:actions>
                <v-icon 
                  :color="selected.gallery === index ? '#FFFFFF' : '#2B3F6C'">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content
              color="transparent">
              <v-row>
                <v-col
                  v-for="(item_img, index_img) in item.image"
                  :key="index_img"
                  cols="6"
                  class="pa-2">
                  <v-img
                    @click="selected.zoom = item_img; dialog.zoom = true;"
                    width="100%"
                    height="150"
                    alt="heha" 
                    :src="item_img"
                    class="ma-auto cursor-pointer"
                    style="border-radius: 15px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-dialog 
      v-model="dialog.zoom"
      width="1000">
      <v-img
        width="100%"
        height="auto"
        alt="heha" 
        :src="selected.zoom"
        class="ma-auto"
        style="border-radius: 15px;">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#F38C35">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      dialog: {
        type: Object,
        default: {}
      },
      selected: {
        type: Object,
        default: {}
      },
      gallery: {
        type: Array,
        default: []
      }
    },
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>