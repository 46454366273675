<template>
  <div>
    <v-container>
      <v-card
        flat
        color="transparent">
        <v-card-title
          class="d-flex align-center pa-3 pt-6"
          style="position: fixed;
                top: 64px;
                width: 100%;
                background: #f9f9f9;">
          <v-btn
            @click="$router.go(-1)"
            icon 
            width="36"
            height="36">
            <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
          </v-btn>

          <span
            class="body-1 font-weight-bold color-purple ml-2">
            Reservasi Tiket Agen
          </span>
        </v-card-title>

        <v-card-text
          class="pt-16 mb-4">
          <div
            class="pt-4 d-flex align-center">
            <div
              class="title font-weight-bold black--text">
              Jadwal Tiket
            </div>

            <v-spacer />

            <div v-if="!process.load && total_remaining !== null">
              <v-chip
                :color="total_remaining > 0 ? '#12BA0E' : 'red'"
                class="white--text">
                {{ total_remaining > 0 ? `Tersedia (${total_remaining})` : message.error }}
              </v-chip>
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <ValidationObserver 
            ref="form">
            <v-form>
              <v-menu
                ref="picker"
                min-width="290px"
                max-width="290px"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                :close-on-content-click="false"
                v-model="picker">
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider 
                    name="ticket_allotment_date" 
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 color-purple mb-2">
                      Tanggal Kunjungan
                    </div>
                    
                    <v-text-field
                      v-on:keyup.enter="save"
                      class="no_box"
                      solo
                      color="#F38C35"
                      readonly
                      placeholder="2022-10-13"
                      prepend-inner-icon="mdi-calendar-month"

                      clearable
                      @click:clear="form.ticket_allotment_date = ''"
                      v-on="on"
                      v-bind="attrs"

                      v-model="form.ticket_allotment_date"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </template>

                <v-date-picker 
                  color="#F38C35"
                  v-model="form.ticket_allotment_date"
                  @input="picker = false; fetch();">
                </v-date-picker>
              </v-menu>

              <v-divider 
                class="mb-7"
                style="border-top: 1px solid #e0e0e0;" />

              <ValidationProvider 
                name="total_ticket" 
                rules="required|min_1:1"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2 color-purple">
                  Jumlah Reservasi Tiket
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  min="0"
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="10"
                  prepend-inner-icon="mdi-ticket-confirmation-outline"

                  @keyup="fetch()"

                  v-model="form.total_ticket"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>
            </v-form>
          </ValidationObserver>

          <div v-if="message.error.length > 0"
            class="text-center my-4 d-flex align-center">
            <div
              class="error--text">
              <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
              
              <span
                class="ml-1">
                {{ message.error }}
              </span>
            </div>
          </div>
        </v-card-text>
        
        <div style="position: relative; max-width: 1135px; margin: auto;">
          <v-card
            flat
            color="#f9f9f9"
            min-height="140"
            style="position: fixed;
                  bottom: 0;
                  max-width: inherit;
                  width: 100%;">
            <v-card-actions
              class="pa-4">
              <v-btn
                :disabled="process.form || total_remaining === null || total_remaining === 0"
                :loading="process.form"
                v-on:keyup.enter="save"
                @click="save"
                x-large
                block
                elevation="0"
                color="#2B3F6C"
                class="body-1 font-weight-bold white--text text-capitalize"
                style="border-radius: 10px;">
                Selanjutnya
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      picker: {
        type: Boolean,
        default: false
      },
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        total_remaining: null,
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      if (this.form.ticket_allotment_type !== '' && this.form.ticket_allotment_date !== '' && this.form.ticket_allotment_date !== null) this.fetch()
    },
    methods: {
      async fetch () {
        this.message.error = ''

        this.process.load = true

        let params = {
          type_ticket: this.form.ticket_allotment_type,
          date: this.form.ticket_allotment_date,
          total_reservasi: this.form.total_ticket
        }
        
        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ticket/search`, { params })
        .then((response) => {
          this.process.load = false

          if (response.code === 200) {
            let item = response.result

            this.form.ticket_allotment_id = item.ticket_allotment_id
            this.form.ticket_allotment_type = item.ticket_allotment_type
            this.form.ticket_allotment_total_price = item.ticket_allotment_total_price

            this.total_remaining = item.ticket_allotment_total_remaining
          } else {
            this.total_remaining = 0
            this.message.error = response.message
          }
        })
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.$store.commit('reservation/setForm', this.form)
          Cookie.set('form', JSON.stringify(this.form))

          this.$router.push({ path: `/reservation/addons/${this.$route.params.slug}` })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>