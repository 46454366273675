<template>
  <div>
    <v-card
      flat
      height="calc(100vh - 270px)"
      color="transparent">
      <v-card-text
        class="px-0 mt-6">

      </v-card-text>

      <div
        style="position: absolute;
              bottom: 0;
              max-width: inherit;
              width: 100%;">
        <v-btn
          @click="$emit('step', {
            title: 'Konfirmasi Transfer',
            value: 5
          })"
          x-large
          block
          elevation="0"
          color="#2B3F6C"
          class="body-1 font-weight-bold white--text text-capitalize"
          style="border-radius: 10px;">
          Checkout
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>