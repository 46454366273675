<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Reservasi Tiket Reguler
              </span>
            </v-card-title>

            <v-card-text
              class="pt-16 pb-0">
              <div
                class="pt-4 d-flex align-center">
                <div
                  class="title font-weight-bold black--text">
                  Informasi Reservasi
                </div>
              </div>
            </v-card-text>

            <v-card-text>
              <v-card
                color="#FFFFFF"
                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                <v-card-title
                  class="d-flex align-center"
                  style="background: #F0F3F9;">
                  <v-img 
                    contain
                    width="5%"
                    height="auto"
                    alt="heha" 
                    :src="require('@/assets/logo/logo_1.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <v-spacer />

                  <div
                    class="caption font-weight-bold color-purple">
                    Reservasi Tiket HeHa Sky View
                  </div>
                </v-card-title>

                <v-card-text
                  class="d-flex align-center py-3">
                  <div
                    class="caption">
                    Kode Booking : <b class="font-weight-bold color-purple">7369123</b>
                  </div>

                  <v-spacer />

                  <div
                    class="caption">
                    Nomor Tiket : <b class="font-weight-bold color-purple">hsk00006</b>
                  </div>
                </v-card-text>

                <v-divider
                  class="mx-4"
                  style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />

                <v-card-text
                  class="py-3">
                  <div
                    class="caption">
                    Tanggal Kunjungan
                  </div>

                  <div
                    class="body-1 font-weight-bold color-purple">
                    10 Desember 2022
                  </div>
                </v-card-text>

                <v-divider
                  class="mx-4"
                  style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />

                <v-card-text
                  class="caption text-right pt-3">
                  Jumlah Tiket : <b class="body-2 font-weight-bold color-purple">1</b>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <div
                class="d-flex align-center mb-4">
                <div
                  class="title font-weight-bold black--text">
                  Add-Ons
                </div>

                <v-spacer />

                <div>
                  <v-img 
                    contain
                    width="20"
                    height="20"
                    alt="heha" 
                    :src="require('@/assets/icon/edit.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </div>

              <v-row>
                <v-col
                  v-for="(item, index) in addons"
                  :key="index"
                  class="py-0">
                  <div
                    class="pb-4">
                    <v-card
                      class="pa-1 pt-0"
                      color="#FFFFFF"
                      style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                      <v-card-text
                        class="pa-3">
                        <v-list 
                          flat 
                          color="transparent"
                          class="py-0">
                          <v-list-item
                            class="pa-0">
                            <v-list-item-content
                              class="pa-0">
                              <v-list-item-title 
                                class="body-1 font-weight-bold color-purple mb-2">
                                {{ item.title }}
                              </v-list-item-title>

                              <v-list-item-subtitle>
                                <div
                                  class="body-2 color-purple mb-2">
                                  {{ item.price }}
                                </div>

                                <div
                                  class="body-2 line-text-first">
                                  {{ item.desc }}
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="pb-16 mb-8">
              <div
                class="d-flex align-center mb-4">
                <div
                  class="title font-weight-bold black--text">
                  Informasi Pemesan
                </div>
              </div>

              <v-card
                color="#FFFFFF"
                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                <v-card-title
                  class="d-flex align-center pb-3">
                  <div
                    class="body-2 font-weight-bold color-purple">
                    Dani Irawan
                  </div>

                  <v-spacer />

                  <div>
                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="heha" 
                      :src="require('@/assets/icon/edit.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            color="#F38C35">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </v-card-title>

                <v-divider
                  class="mx-4"
                  style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />

                <v-card-text>
                  <div
                    class="d-flex align-center mb-2">
                    <div>
                      <v-img 
                        contain
                        width="18"
                        height="18"
                        alt="heha" 
                        :src="require('@/assets/icon/call_color.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div
                      class="body-2 ml-2">
                      082123456789
                    </div>
                  </div>

                  <div
                    class="d-flex align-center">
                    <div>
                      <v-img 
                        contain
                        width="18"
                        height="18"
                        alt="heha" 
                        :src="require('@/assets/icon/email.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div
                      class="body-2 ml-2">
                      daniirawan@gmail.com
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card
              flat
              color="#f9f9f9"
              min-height="140"
              style="position: fixed;
                    bottom: 0;
                    width: 460px;">
              <v-card-text
                class="pa-4">
                <v-btn
                  to="/reservation/invoice/tiketreguler"
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize mb-4"
                  style="border-radius: 10px;">
                  Lanjut Pembayaran
                </v-btn>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        addons: [
          {
            title: 'Snack',
            price: '+ Rp 10.000 / Tiket',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            title: 'Photo',
            price: '+ Rp 10.000 / Tiket',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          }
        ],
        // SEO
        content: {
          url: '/reservation/payment/:slug',
          title: 'Pembayaran Reservasi Tiket Reguler',
          description: 'Pembayaran Reservasi Tiket Reguler HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>