<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <Mobile
            class="mobile py-14"
            :form="form" />

          <Desktop
            class="desktop py-16"
            :form="form" />
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        form: {
          ticket_allotment_id: '',
          total_ticket: 1,
          ticket_allotment_type: '',
          ticket_allotment_date: '',
          ticket_allotment_total_price: 0,
          user_full_name: '',
          user_wa_number: '',
          user_email: '',
          user_sosmed_account: '',
          addon_items: [],
          payment_method: '',
          voucher_code: '',
          voucher_discount_nominal: 0,
          note: '',
          total_price: 0
        },
        // SEO
        content: {
          url: '/reservation/buyer/:slug',
          title: 'Informasi Pemesan Reservasi Tiket Reguler',
          description: 'Informasi Pemesan Reservasi Tiket Reguler HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Mobile: () => import(/* webpackPrefetch: true */ '@/components/mobile/reservation/buyer.vue'),
      Desktop: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/buyer.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      if (Cookie.get('form') !== undefined) {
        this.$store.commit('reservation/setForm', JSON.parse(Cookie.get('form')))
  
        this.form = JSON.parse(Cookie.get('form'))
      }
    },
    methods: {
      
    }
  }
</script>