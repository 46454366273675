<template>
  <div>
    <v-card
      flat
      color="transparent">
      <v-card-text
        class="text-center">
        <v-carousel 
          v-model="selected.banner"
          height="252"
          hide-delimiters
          show-arrows-on-hover
          style="border-radius: 15px;">
          <v-carousel-item 
            v-for="(item, index) in process.load ? 6 : datas.banners" 
            :key="index" 
            reverse-transition="fade-transition"
            transition="fade-transition">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.load"
              type="image, image, image"
              style="border-radius: 0px;">
              <div>
                <v-img v-if="!process.laod && datas.banners.length > 0"
                  width="100%"
                  height="252"
                  alt="heha" 
                  gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .1)"
                  :src="item.banner_image"
                  class="ma-auto d-flex align-center">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>

                  <div
                    class="white--text text-left pa-8">
                    <h1
                      class="title font-weight-bold"
                      v-html="item.banner_title.replace(/(<([^>]+)>)/ig, '')">
                    </h1>

                    <h2
                      class="body-1 font-weight-bold mb-4">
                      in <span class="color-default">Yogyakarta.</span>
                    </h2>

                    <h3
                      class="body-2 line-text-first">
                      <span
                        class="four-line"
                        v-html="item.banner_description.replace(/(<([^>]+)>)/ig, '')">
                      </span>
                    </h3>
                  </div>
                </v-img>
              </div>
            </v-skeleton-loader>
          </v-carousel-item>
        </v-carousel>

        <v-btn
          to="/reservation"
          x-large
          color="#F38C35"
          class="text-capitalize white--text border-radius font-weight-bold px-10"
          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; position: relative; top: -26px;">
          Reservasi Tiket Sekarang
        </v-btn>

        <v-card
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                border-radius: 15px;">
          <no-ssr>
            <swiper 
              class="swiper"
              ref="swiper"
              :options="{ slidesPerView: 'auto' }">
              <swiper-slide 
                v-for="(item, index) in menu" 
                :key="index" 
                class="py-2" 
                :class="index < 1 ? 'px-2' : index < menu.length ? 'pr-2' : 'px-2'"
                style="width: auto;">
                <v-card
                  flat
                  width="50"
                  height="85"
                  class="d-flex justify-center pa-2 a_menu">
                  <div>
                    <v-avatar
                      size="50"
                      color="#F0F3F9"
                      class="ma-auto d-flex align-center border-radius">
                      <v-img 
                        contain
                        width="26"
                        height="26"
                        alt="heha" 
                        :src="item.icon"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div
                      class="mt-2 color-grey line-text-first a_text"
                      style="font-size: 10px;">
                      {{ item.title }}
                    </div>
                  </div>
                </v-card>
              </swiper-slide>
            </swiper>
          </no-ssr>
        </v-card>
      </v-card-text>

      <v-card-text>
        <div
          class="body-1 font-weight-bold black--text mb-2">
          Lokasi
        </div>

        <v-card v-if="Object.keys(currentPosition).length > 0"
          class="pa-1 mb-6"
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                border-radius: 15px;">
          <gmap-map
            ref="gmap"
            :center="center"
            :zoom="16"
            :scrollwheel="false"
            style="width:100%; height: 200px;">
            <gmap-marker
              :position="currentPosition.latlng"
              :icon="currentPosition.icon">
            </gmap-marker>
          </gmap-map>
        </v-card>

        <div>
          <v-img 
            contain
            width="26"
            height="26"
            alt="heha" 
            :src="require('@/assets/icon/marker.png')"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  color="#F38C35">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-2 black--text text-center my-3">
            Jl. Dlingo-Patuk No.2, Bukit, Patuk, Gunung Kidul Regency, Special Region Of Yogyakarta 55862
          </div>

          <v-row>
            <v-col
              cols="6">
              <v-card
                flat
                color="#F0F3F9"
                class="pa-4"
                style="border-radius: 15px;">
                <v-img 
                  contain
                  width="100%"
                  height="26"
                  alt="heha" 
                  :src="require('@/assets/icon/call_color.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="caption black--text mt-2 text-center one-line">
                  0812-1700-4900
                </div>
              </v-card>
            </v-col>

            <v-col
              cols="6">
              <v-card
                flat
                color="#F0F3F9"
                class="pa-4"
                style="border-radius: 15px;">
                <v-img 
                  contain
                  width="100%"
                  height="26"
                  alt="heha" 
                  :src="require('@/assets/icon/email.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="caption black--text mt-2 text-center one-line">
                  hehaskyview@gmail.com
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text 
        class="py-0">
        <div
          class="d-flex align-center">
          <div
            class="body-1 font-weight-bold black--text">
            Venue
          </div>

          <v-spacer />

          <nuxt-link
            to="/venue">
            <span
              class="body-2 color-default">
              Selengkapnya
            </span>
          </nuxt-link>
        </div>
      </v-card-text>

      <no-ssr>
        <swiper 
          class="swiper"
          ref="swiper"
          :options="{ slidesPerView: 'auto' }">
          <swiper-slide 
            v-for="(item, index) in process.load ? 6 : datas.venues" 
            :key="index" 
            class="py-3" 
            :class="index < 1 ? 'px-4' : index < datas.venues.length ? 'pr-4' : 'px-4'"
            style="width: auto;">
            <v-skeleton-loader
              class="ma-auto"
              width="200"
              :loading="process.load"
              type="card">
              <div>
                <v-card v-if="!process.laod && datas.venues.length > 0"
                  class="pa-1"
                  color="#FFFFFF"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;"
                  width="200">
                  <v-img
                    width="100%"
                    height="125"
                    alt="heha" 
                    :src="item.venue_image"
                    class="ma-auto"
                    style="border-radius: 15px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <v-card-text
                    class="pa-3">
                    <div
                      class="body-1 font-weight-bold black--text mb-1">
                      {{ item.venue_title }}
                    </div>

                    <div
                      class="body-2 three-line">
                      {{ item.venue_description }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-skeleton-loader>
          </swiper-slide>
        </swiper>
      </no-ssr>

      <v-card-text>
        <div
          class="d-flex align-center mb-4">
          <div
            class="body-1 font-weight-bold black--text">
            Galeri
          </div>

          <v-spacer />

          <nuxt-link
            to="/gallery">
            <span
              class="body-2 color-default">
              Selengkapnya
            </span>
          </nuxt-link>
        </div>

        <v-skeleton-loader
          class="ma-auto"
          :loading="process.load"
          type="image">
          <div>
            <v-img v-if="!process.laod && datas.galleries.length > 0"
              width="100%"
              height="252"
              alt="heha" 
              :src="datas.galleries[selected.gallery].gallery_image"
              class="ma-auto"
              style="border-radius: 15px;">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    indeterminate 
                    color="#F38C35">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </v-skeleton-loader>
      </v-card-text>

      <no-ssr>
        <swiper 
          class="swiper"
          ref="swiper"
          :options="{ slidesPerView: 'auto' }">
          <swiper-slide 
            v-for="(item, index) in process.load ? 6 : datas.galleries" 
            :key="index" 
            class="py-3" 
            :class="index < 1 ? 'px-4' : index < datas.galleries.length ? 'pr-4' : 'px-4'"
            style="width: auto;">
            <v-skeleton-loader
              class="ma-auto"
              width="110"
              height="80"
              :loading="process.load"
              type="image">
              <div>
                <v-card v-if="!process.laod && datas.galleries.length > 0"
                  @click="selected.gallery = index"
                  color="#FFFFFF"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;"
                  width="110">
                  <v-img
                    width="100%"
                    height="80"
                    alt="heha" 
                    :src="item.gallery_image"
                    class="ma-auto"
                    style="border-radius: 15px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </div>
            </v-skeleton-loader>
          </swiper-slide>
        </swiper>
      </no-ssr>

      <v-card-text
        class="mt-3 pb-0">
        <div
          class="d-flex align-center">
          <div
            class="body-1 font-weight-bold black--text">
            Testimoni
          </div>

          <v-spacer />

          <nuxt-link
            to="/testimony">
            <span
              class="body-2 color-default">
              Lihat semua
            </span>
          </nuxt-link>
        </div>
      </v-card-text>

      <no-ssr>
        <swiper 
          class="swiper"
          ref="swiper"
          :options="{ slidesPerView: 'auto' }">
          <swiper-slide 
            v-for="(item, index) in process.load ? 6 : datas.testimonies" 
            :key="index" 
            class="py-3" 
            :class="index < 1 ? 'px-4' : index < datas.testimonies.length ? 'pr-4' : 'px-4'"
            style="width: auto;">
            <v-skeleton-loader
              class="ma-auto"
              width="300"
              :loading="process.load"
              type="card">
              <div>
                <v-card v-if="!process.laod && datas.testimonies.length > 0"
                  class="pa-1 pt-0"
                  color="#FFFFFF"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;"
                  width="300">
                  <v-card-text
                    class="pa-3">
                    <v-list 
                      flat 
                      color="transparent"
                      class="pt-1 pb-3">
                      <v-list-item
                        class="pa-0">
                        <v-list-item-avatar
                          color="#F0F3F9"
                          size="50"
                          class="border-radius my-0">
                          <v-img 
                            contain
                            width="35"
                            height="35"
                            alt="heha" 
                            :src="item.testimony_user_image === '' ? require('@/assets/icon/user.png') : item.testimony_user_image"
                            class="ma-auto"
                            style="border-radius: 10px;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#F38C35">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content
                          class="pa-0">
                          <v-list-item-title 
                            class="body-1 font-weight-bold mb-2">
                            {{ item.testimony_user_name }}
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            {{ item.testimony_date | date }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <div
                      class="body-2 line-text-first five-line mt-1"
                      style="max-width: 277px;">
                      "{{ item.testimony_content }}"
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-skeleton-loader>
          </swiper-slide>
        </swiper>
      </no-ssr>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      selected: {
        type: Object,
        default: {}
      },
      datas: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      },
      menu: {
        type: Array,
        default: []
      },
      center: {
        type: Object,
        default: {}
      },
      currentPosition: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped>
  .a_menu:hover {
    background: #F0F3F9;
    border-radius: 10px;
  }

  .a_menu:hover .v-avatar {
    background: #FFFFFF !important;
  }

  .a_menu:hover .a_text {
    font-weight: bold;
    color: #000000;
  }
</style>