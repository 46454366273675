<template>
  <div>
    <v-card
      flat
      height="calc(100vh - 270px)"
      color="transparent">
      <v-card-text
        class="px-0 mt-6">
        <ValidationObserver 
          ref="form">
          <v-form>
            <ValidationProvider 
              name="name" 
              rules="required"
              v-slot="{ errors }">
              <div class="body-2 mb-2 color-purple">
                Nama Lengkap
              </div>
              
              <v-text-field
                class="no_box"
                solo
                color="#F38C35"
                placeholder="John Doe"
                prepend-inner-icon="mdi-account-outline"

                v-model="form.phone"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <ValidationProvider 
              name="phone" 
              rules="required|min:8|max:13"
              v-slot="{ errors }">
              <div class="body-2 mb-2 color-purple">
                Nomor WhatsApp
              </div>
              
              <v-text-field
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                min="0"
                class="no_box"
                solo
                color="#F38C35"
                placeholder="123 456 789 010"
                prepend-inner-icon="mdi-phone-in-talk-outline"

                v-model="form.phone"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <ValidationProvider 
              name="email" 
              rules="required|email"
              v-slot="{ errors }">
              <div class="body-2 mb-2 color-purple">
                Email (Opsional)
              </div>
              
              <v-text-field
                class="no_box"
                solo
                color="#F38C35"
                placeholder="example@example.com"
                prepend-inner-icon="mdi-email-outline"

                v-model="form.email"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <ValidationProvider 
              name="instagram" 
              rules="required"
              v-slot="{ errors }">
              <div class="body-2 mb-2 color-purple">
                Alamat Instagram (Opsional)
              </div>
              
              <v-text-field
                class="no_box"
                solo
                color="#F38C35"
                placeholder="@username"
                prepend-inner-icon="mdi-instagram"

                v-model="form.instagram"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <div
              class="body-2 line-text-first">
              *Dapatkan merchandise menarik dari HeHa Sky View apabila kamu mengisi alamat Instagram.
            </div>
          </v-form>
        </ValidationObserver>
      </v-card-text>

      <div
        style="position: absolute;
              bottom: 0;
              max-width: inherit;
              width: 100%;">
        <v-btn
          @click="$emit('step', {
            title: 'Detail Reservasi',
            value: 3
          })"
          x-large
          block
          elevation="0"
          color="#2B3F6C"
          class="body-1 font-weight-bold white--text text-capitalize"
          style="border-radius: 10px;">
          Selanjutnya
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>