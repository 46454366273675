<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <Mobile
            class="mobile py-14"
            :dialog="dialog"
            :selected="selected"
            :gallery="gallery" />

          <Desktop
            class="desktop py-16"
            :dialog="dialog"
            :selected="selected"
            :gallery="gallery" />
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          zoom: false
        },
        selected: {
          gallery: 0,
          zoom: null
        },
        gallery: [
          {
            title: 'Spot Foto',
            image: [
              require('@/assets/image/example/Venue1.jpg'),
              require('@/assets/image/example/Venue2.jpg'),
              require('@/assets/image/example/Venue3.jpg'),
              require('@/assets/image/example/Venue4.jpeg'),
              require('@/assets/image/example/Venue5.jpeg')
            ]
          },
          {
            title: 'Venue',
            image: [
              require('@/assets/image/example/Venue1.jpg'),
              require('@/assets/image/example/Venue2.jpg'),
              require('@/assets/image/example/Venue3.jpg'),
              require('@/assets/image/example/Venue4.jpeg'),
              require('@/assets/image/example/Venue5.jpeg')
            ]
          },
          {
            title: 'Kuliner',
            image: [
              require('@/assets/image/example/Venue1.jpg'),
              require('@/assets/image/example/Venue2.jpg'),
              require('@/assets/image/example/Venue3.jpg'),
              require('@/assets/image/example/Venue4.jpeg'),
              require('@/assets/image/example/Venue5.jpeg')
            ]
          },
          {
            title: 'Lainnya',
            image: [
              require('@/assets/image/example/Venue1.jpg'),
              require('@/assets/image/example/Venue2.jpg'),
              require('@/assets/image/example/Venue3.jpg'),
              require('@/assets/image/example/Venue4.jpeg'),
              require('@/assets/image/example/Venue5.jpeg')
            ]
          }
        ],
        list: [],
        pagination: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/gallery',
          title: 'Galeri',
          description: 'Galeri HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Mobile: () => import(/* webpackPrefetch: true */ '@/components/mobile/gallery.vue'),
      Desktop: () => import(/* webpackPrefetch: true */ '@/components/desktop/gallery.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/gallery`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.list = response.result.data
            this.pagination = response.result.meta
          } 
        })
      }
    }
  }
</script>