<template>
  <div>
    <v-card
      flat
      color="transparent">
      <v-card-title
        class="d-flex align-center pa-3"
        style="position: fixed;
              top: 100 px;
              width: 460px;
              background: #f9f9f9;
              z-index: 2;">
        <v-btn
          @click="$router.go(-1)"
          icon 
          width="36"
          height="36">
          <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
        </v-btn>

        <span
          class="body-1 font-weight-bold color-purple ml-2">
          Reservasi Tiket
        </span>
      </v-card-title>
      
      <v-card-text
        class="pt-16">
        <v-expansion-panels
          class="pt-4"
          flat>
          <v-expansion-panel
            style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
            <v-expansion-panel-header
              class="py-2">
              <v-list 
                flat 
                dense
                color="transparent"
                class="pa-0">
                <v-list-item
                  class="d-flex align-center px-0">
                  <v-list-item-avatar
                    size="20"
                    class="ma-0 mr-3">
                    <v-img 
                      contain
                      alt="heha" 
                      :src="require('@/assets/icon/lamp.png')"
                      class="ma-auto"
                      style="border-radius: 10px;">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#F38C35">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <div
                      class="body-1 font-weight-bold color-purple">
                      Pengumuman
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div
                class="pa-4 pt-0">
                <v-divider
                  class="mb-4"
                  style="border-top: 1px solid #e0e0e0;" />

                <div v-if="Object.keys(data.announcement).length > 0">
                  <div
                    class="body-2 line-text-first font-weight-bold mb-2">
                    {{ data.announcement.announcement_title }}
                  </div>

                  <div
                    class="body-2 line-text-first"
                    v-html="data.announcement.announcement_description.replace(/(<([^>]+)>)/ig, '')">
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-text
        class="mt-8 pt-2 pb-0">
        <v-btn
          @click="dialog.check = true"
          block
          x-large
          color="#F38C35"
          class="text-capitalize white--text border-radius font-weight-bold px-10"
          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; position: relative; top: -26px;">
          Cek Reservasi Tiket
        </v-btn>
      </v-card-text>

      <v-card-text
        class="py-0">
        <v-row>
          <v-col
            v-for="(item, index) in process.load ? 2 : list"
            :key="index"
            cols="12">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.load"
              type="image, article">
              <div>
                <div v-if="!process.load && list.length > 0">
                  <div
                    class="d-flex align-center mb-3">
                    <div
                      class="body-1 font-weight-bold black--text text-capitalize">
                      Tiket {{ item.ticket_type }}
                    </div>

                    <v-spacer />

                    <nuxt-link
                      :to="`/reservation/${item.ticket_uuid}`">
                      <span
                        class="body-2 color-default">
                        Detail
                      </span>
                    </nuxt-link>
                  </div>

                  <v-img
                    width="100%"
                    height="252"
                    alt="heha" 
                    :src="item.ticket_image"
                    class="ma-auto pa-2 d-flex align-end"
                    style="border-radius: 15px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <v-card
                      flat
                      color="#FFFFFFCC"
                      style="border-radius: 15px;">
                      <v-card-text
                        class="body-1 font-weight-bold color-purple d-flex align-center pb-0">
                        <v-avatar 
                          size="20"
                          tile>
                          <v-img
                            contain
                            alt="heha" 
                            :src="require('@/assets/icon/ticket.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate 
                                  color="#F38C35">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                        
                        <span
                          class="ml-3 one-line text-capitalize">
                          Reservasi Tiket {{ item.ticket_type }}
                        </span>
                      </v-card-text>

                      <v-divider
                        class="ma-4 my-3"
                        style="border-top: 1px solid #FFFFFFCC;" />

                      <v-card-text
                        class="body-2 line-text-first two-line py-0">
                        {{ item.ticket_description }}
                      </v-card-text>

                      <v-card-actions
                        class="pa-4">
                        <div
                          class="body-1 font-weight-bold color-purple">
                          {{ item.ticket_price | price }} / Orang
                        </div>

                        <v-spacer />

                        <v-btn
                          @click="toCookie(item.ticket_uuid)"
                          outlined
                          elevation="0"
                          color="#2B3F6C"
                          class="body-1 font-weight-bold color-purple text-capitalize px-5"
                          style="border-radius: 10px;">
                          Reservasi
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-img>
                </div>
              </div>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text
        class="body-2 color-default text-center">
        Syarat & Ketentuan
      </v-card-text>
    </v-card>

    <v-dialog 
      v-model="dialog.check">
      <v-card 
        class="py-6"
        style="border-radius: 25px;">
        <v-card-text
          class="pb-0">
          <div
            class="body-1 font-weight-bold color-purple mb-3">
            Cek Reservasi Tiket
          </div>

          <div
            class="body-2 line-text-first">
            Cek reservasi tiket kamu dengan mengisi form dibawah ini
          </div>

          <v-divider
            class="my-6"
            style="border-top: 1px solid #e0e0e0;" />

          <ValidationObserver 
            ref="form">
            <v-form>
              <ValidationProvider 
                name="booking_code" 
                rules="required"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2 color-purple">
                  Kode Booking
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="Masukkan Kode Booking"

                  v-model="form.booking_code"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider 
                name="phone_number" 
                rules="required|min:8|max:13"
                v-slot="{ errors, valid }">
                <div class="body-2 mb-2 color-purple">
                  Nomor WhatsApp
                </div>
                
                <v-text-field
                  v-on:keyup.enter="save"
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  min="0"
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="123 456 789 010"
                  prepend-inner-icon="mdi-phone-in-talk-outline"

                  v-model="form.phone_number"
                  :success="valid"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>
            </v-form>
          </ValidationObserver>

          <div v-if="message.error.length > 0"
            class="text-center my-4 d-flex align-center">
            <div
              class="error--text">
              <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
              
              <span
                class="ml-1">
                {{ message.error }}
              </span>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :disabled="process.form"
            :loading="process.form"
            v-on:keyup.enter="save"
            @click="save"
            x-large
            block
            elevation="0"
            color="#2B3F6C"
            class="body-1 font-weight-bold white--text text-capitalize"
            style="border-radius: 10px;">
            Cek Reservasi Tiket Kamu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      list: {
        type: Array,
        default: []
      },
      pagination: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      },
      dialog: {
        type: Object,
        default: {}
      },
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        data: {
          announcement: {}
        },
        form_cookie: {
          ticket_allotment_id: '',
          total_ticket: 1,
          ticket_allotment_type: '',
          ticket_allotment_date: '',
          ticket_allotment_total_price: 0,
          user_full_name: '',
          user_wa_number: '',
          user_email: '',
          user_sosmed_account: '',
          addon_items: [],
          payment_method: '',
          voucher_code: '',
          voucher_discount_nominal: 0,
          note: '',
          total_price: 0
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/announcement`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.data.announcement = response.result
          } 
        })
      },
      toCookie (uuid) {
        this.$store.commit('reservation/setForm', this.form_cookie)
        Cookie.set('form', JSON.stringify(this.form_cookie))

        this.$router.push({ path: `/reservation/ticket/${uuid}` })
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let params = this.form

          // SAVE TO REST API
          await this.$axios.$get(`${process.env.PREFIX_PROXY}/booking/history`, { params })
          .then((response) => {

            if (response.code === 200) {
              this.process.form = false
              
              // this.$router.push({ path: '/reservation/check/qrcode/tiketreguler' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>