<template>
  <div>
    <v-card
      flat
      color="transparent">
      <v-card-title
        class="d-flex align-center pa-3"
        style="position: fixed;
              top: 100 px;
              width: 460px;
              background: #f9f9f9;
              z-index: 2;">
        <v-btn
          @click="$router.go(-1)"
          icon 
          width="36"
          height="36">
          <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
        </v-btn>

        <span
          class="body-1 font-weight-bold color-purple ml-2">
          Venue
        </span>
      </v-card-title>
      
      <v-card-text
        class="pt-16">
        <div
          class="mt-4">
          <v-skeleton-loader
            v-for="(item, index) in process.load ? 6 : list"
            :key="index"
            class="ma-auto"
            :loading="process.load"
            type="image, article">
            <div>
              <v-card v-if="!process.load && list.length > 0"
                flat
                :class="index < list.length - 1 ? 'mb-6' : ''"
                color="transparent">
                <v-img
                  width="100%"
                  height="200"
                  alt="heha" 
                  :src="item.venue_image"
                  class="ma-auto pa-2 d-flex align-end"
                  style="border-radius: 15px;">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-1 font-weight-bold black--text mt-6 mb-2">
                  {{ item.venue_title }}
                </div>

                <div
                  class="body-2 line-text-first"
                  style="opacity: 70%;">
                  {{ item.venue_description }}
                </div>
              </v-card>
            </div>
          </v-skeleton-loader>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      menu: {
        type: Array,
        default: []
      },
      list: {
        type: Array,
        default: []
      },
      pagination: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>