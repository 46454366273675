<template>
  <div>
    <v-container>
      <v-card
        flat
        color="transparent">
        <v-card-title
          class="d-flex align-center pa-3 pt-6"
          style="position: fixed;
                top: 64px;
                width: 100%;
                background: #f9f9f9;
                z-index: 2;">
          <v-btn
            @click="$router.go(-1)"
            icon 
            width="36"
            height="36">
            <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
          </v-btn>

          <span
            class="body-1 font-weight-bold color-purple ml-2">
            Reservasi Tiket Agen
          </span>
        </v-card-title>

        <v-card-text
          class="pt-16 pb-0">
          <div
            class="pt-4 d-flex align-center">
            <div
              class="title font-weight-bold black--text">
              Informasi Reservasi
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <v-card
            color="#FFFFFF"
            style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
            <v-card-title
              class="d-flex align-center"
              style="background: #F0F3F9;">
              <div>
                <v-img 
                  contain
                  width="150"
                  height="auto"
                  alt="heha" 
                  :src="require('@/assets/logo/logo_1.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <v-spacer />

              <div
                class="body-1 font-weight-bold color-purple">
                Reservasi Tiket HeHa Sky View
              </div>
            </v-card-title>

            <v-card-text
              class="d-flex align-center py-3">
              <!-- <div
                class="body-2">
                Kode Booking : <b class="font-weight-bold color-purple">-</b>
              </div> -->

              <div
                class="body-2">
                Tiket : <b class="font-weight-bold color-purple text-capitalize">{{ form.ticket_allotment_type }}</b>
              </div>

              <v-spacer />

              <!-- <div
                class="body-2">
                Nomor Tiket : <b class="font-weight-bold color-purple">-</b>
              </div> -->
            </v-card-text>

            <v-divider
              class="mx-4"
              style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />

            <v-card-text
              class="py-3">
              <div
                class="body-2 mb-1">
                Tanggal Kunjungan
              </div>

              <div
                class="body-1 font-weight-bold color-purple">
                {{ form.ticket_allotment_date | date }}
              </div>
            </v-card-text>

            <v-divider
              class="mx-4"
              style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />

            <v-card-text
              class="body-2 text-right pt-3">
              Jumlah Tiket : <b class="body-2 font-weight-bold color-purple">{{ form.total_ticket }}</b>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <div
            class="d-flex align-center mb-4">
            <div
              class="title font-weight-bold black--text">
              Add-Ons
            </div>

            <v-spacer />

            <div>
              <nuxt-link
                :to="`/reservation/addons/${$route.params.slug}`">
                <v-img 
                  contain
                  width="20"
                  height="20"
                  alt="heha" 
                  :src="require('@/assets/icon/edit.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>
          </div>

          <v-row>
            <v-col
              v-for="(item, index) in form.addon_items"
              :key="index"
              cols="12"
              class="py-0">
              <div
                class="pb-4">
                <v-card
                  class="pa-1 pt-0"
                  color="#FFFFFF"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                  <v-card-text
                    class="pa-3">
                    <v-list 
                      flat 
                      color="transparent"
                      class="py-0">
                      <v-list-item
                        class="pa-0">
                        <v-list-item-content
                          class="pa-0">
                          <v-list-item-title 
                            class="body-1 font-weight-bold color-purple mb-2">
                            {{ item.addon_title }}
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            <div
                              class="body-2 color-purple mb-2">
                              + {{ item.addon_price | price }} / Tiket
                            </div>

                            <div
                              class="body-2 line-text-first">
                              {{ item.addon_description }}
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>

          <div v-if="form.addon_items.length < 1"
            class="pb-4">
            Add-Ons tidak dipilih.
          </div>
        </v-card-text>

        <v-card-text
          class="pb-16 mb-8">
          <div
            class="d-flex align-center mb-4">
            <div
              class="title font-weight-bold black--text">
              Informasi Pemesan
            </div>
          </div>

          <v-card
            color="#FFFFFF"
            style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
            <v-card-title
              class="d-flex align-center pb-3">
              <div
                class="body-1 font-weight-bold color-purple">
                {{ form.user_full_name }}
              </div>

              <v-spacer />

              <div>
                <nuxt-link
                  :to="`/reservation/buyer/${$route.params.slug}`">
                  <v-img 
                    contain
                    width="20"
                    height="20"
                    alt="heha" 
                    :src="require('@/assets/icon/edit.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </nuxt-link>
              </div>
            </v-card-title>

            <v-divider
              class="mx-4"
              style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />

            <v-card-text>
              <div
                class="d-flex align-center mb-2">
                <div>
                  <v-img 
                    contain
                    width="18"
                    height="18"
                    alt="heha" 
                    :src="require('@/assets/icon/call_color.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div
                  class="body-2 ml-2">
                  {{ form.user_wa_number }}
                </div>
              </div>

              <div
                class="d-flex align-center">
                <div>
                  <v-img 
                    contain
                    width="18"
                    height="18"
                    alt="heha" 
                    :src="require('@/assets/icon/email.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="20"
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div
                  class="body-2 ml-2">
                  {{ form.user_email === '' ? '-' : form.user_email }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>

        <div style="position: relative; max-width: 1135px; margin: auto;">
          <v-card
            flat
            color="#f9f9f9"
            min-height="140"
            style="position: fixed;
                  bottom: 0;
                  max-width: inherit;
                  width: 100%;">
            <v-card-text
              class="pa-4">
              <v-btn
                :to="`/reservation/invoice/${$route.params.slug}`"
                x-large
                block
                elevation="0"
                color="#2B3F6C"
                class="body-1 font-weight-bold white--text text-capitalize mb-4"
                style="border-radius: 10px;">
                Lanjut Pembayaran
              </v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>