<template>
  <div>
    <v-card
      flat
      color="transparent">
      <v-card-title
        class="d-flex align-center pa-3"
        style="position: fixed;
              top: 100 px;
              width: 460px;
              background: #f9f9f9;
              z-index: 2;">
        <v-btn
          @click="$router.go(-1)"
          icon 
          width="36"
          height="36">
          <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
        </v-btn>

        <span
          class="body-1 font-weight-bold color-purple ml-2">
          Detail Tiket Reguler
        </span>
      </v-card-title>

      <v-skeleton-loader 
        class="ma-auto"
        :loading="process.load"
        type="image, article, list-item-three-line, actions">
        <div>
          <div v-if="!process.load && Object.keys(detail).length > 0">
            <div
              class="pt-16">
              <div
                class="pt-4">
                <v-img
                  width="100%"
                  height="222"
                  alt="heha" 
                  :src="detail.ticket_image"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>

            <v-card-text
              class="pb-16 mb-12">
              <div
                class="title font-weight-bold color-purple mb-2 text-capitalize">
                Reservasi Tiket {{ detail.ticket_type }}
              </div>

              <div
                class="body-2 line-text-first mb-4">
                {{ detail.ticket_description }}
              </div>
            </v-card-text>

            <v-card
              flat
              color="#f9f9f9"
              min-height="180"
              style="position: fixed;
                    bottom: 0;
                    width: 460px;">
              <v-card-text
                class="pt-2 pb-0">
                <div
                  class="title font-weight-bold color-purple">
                  {{ detail.ticket_price | price }} / Orang
                </div>
              </v-card-text>

              <v-card-actions
                class="pa-4">
                <v-btn
                  @click="toCookie(detail.ticket_uuid)"
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize"
                  style="border-radius: 10px;">
                  Reservasi
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        form: {
          ticket_allotment_id: '',
          total_ticket: 1,
          ticket_allotment_type: '',
          ticket_allotment_date: '',
          ticket_allotment_total_price: 0,
          user_full_name: '',
          user_wa_number: '',
          user_email: '',
          user_sosmed_account: '',
          addon_items: [],
          payment_method: '',
          voucher_code: '',
          voucher_discount_nominal: 0,
          note: '',
          total_price: 0
        }
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      toCookie (uuid) {
        this.$store.commit('reservation/setForm', this.form)
        Cookie.set('form', JSON.stringify(this.form))

        this.$router.push({ path: `/reservation/ticket/${uuid}` })
      }
    }
  }
</script>