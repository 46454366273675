<template>
  <v-card 
    flat 
    class="overflow-hidden"
    style="border-radius: 0px;">
    <v-app-bar 
      v-if="!loader"
      app
      fixed
      color="#FFFFFF"
      :hide-on-scroll="false"
      :prominent="false"
      scroll-target="#scrolling-techniques-4"
      class="px-2"
      style="z-index: 2; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
      <v-btn
        icon 
        width="36"
        height="36">
        <v-img 
          contain
          width="20"
          height="20"
          alt="heha" 
          :src="require('@/assets/icon/translate.png')"
          class="ma-auto">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                size="20"
                indeterminate 
                color="#F38C35">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-btn>

      <v-spacer />

      <div>
        <nuxt-link
          to="/home">
          <v-img 
            contain
            width="100"
            height="auto"
            alt="heha" 
            :src="require('@/assets/logo/logo_1.png')"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  color="#F38C35">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </nuxt-link>
      </div>

      <v-spacer />

      <v-btn
        @click.stop="drawer = !drawer"
        icon 
        width="36"
        height="36">
        <v-img 
          contain
          width="26"
          height="26"
          alt="heha" 
          :src="require('@/assets/icon/menu.png')"
          class="ma-auto">
          <template v-slot:placeholder>
            <v-row
              class="fill-height"
              align="center"
              justify="center">
              <v-progress-circular 
                size="20"
                indeterminate 
                color="#F38C35">
              </v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      right
      temporary>
      <v-card
        flat
        class="pa-4">
        <div
          class="mb-6">
          <v-img 
            contain
            width="100"
            height="auto"
            alt="heha" 
            :src="require('@/assets/logo/logo_1.png')"
            class="ma-auto">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular 
                  size="20"
                  indeterminate 
                  color="#F38C35">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>

        <v-btn
          @click="drawer = false; dialog.auth = true;"
          block
          elevation="0"
          color="#2B3F6C"
          class="body-2 font-weight-bold white--text text-capitalize"
          style="border-radius: 8px;">
          Masuk/Daftar Agen
        </v-btn>
      </v-card>

      <v-list>
        <v-list-item-group
          v-model="selected.menu">
          <v-list-item
            v-for="(item, index) in menu"
            :key="index"
            link
            color="#2B3F6C80"
            :to="item.link">
            <v-list-item-icon
              class="mr-3">
              <v-img 
                contain
                width="22"
                height="22"
                alt="heha" 
                :src="item.icon"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                :class="$route.path === item.link ? 'font-weight-bold' : ''"
                style="color: #2B3F6C;">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-sheet 
      id="scrolling-techniques-4"
      class="overflow-y-auto"
      max-height="100vh"
      @scroll="onScroll"
      style="background: #f9f9f9;">
      <div
        style="min-height: 100vh;">
        <Loader v-if="loader" />
        
        <nuxt />
      </div>
    </v-sheet>

    <v-dialog 
      v-model="dialog.auth">
      <v-card 
        class="py-4"
        style="border-radius: 25px;">
        <v-card-text
          class="pb-0">
          <div
            class="mb-12">
            <v-img 
              contain
              width="100"
              height="auto"
              alt="heha" 
              :src="require('@/assets/logo/logo_1.png')"
              class="ma-auto">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    size="20"
                    indeterminate 
                    color="#F38C35">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>

          <v-row>
            <v-col
              cols="6">
              <v-btn
                @click="selected.auth = 'login'"
                block
                elevation="0"
                :color="selected.auth === 'login' ? '#2B3F6C' : '#E2E7F3'"
                :class="selected.auth === 'login' ? 'white--text font-weight-bold' : 'color-purple'"
                class="body-2 text-capitalize"
                style="border-radius: 8px;">
                Masuk
              </v-btn>
            </v-col>

            <v-col
              cols="6">
              <v-btn
                @click="selected.auth = 'register'"
                block
                elevation="0"
                :color="selected.auth === 'register' ? '#2B3F6C' : '#E2E7F3'"
                :class="selected.auth === 'register' ? 'white--text font-weight-bold' : 'color-purple'"
                class="body-2 text-capitalize"
                style="border-radius: 8px;">
                Daftar
              </v-btn>
            </v-col>
          </v-row>

          <v-divider
            class="mt-2 mb-4"
            style="border-top: 1px solid #e0e0e0;" />

          <ValidationObserver 
            ref="form">
            <v-form v-if="selected.auth === 'login'">
              <ValidationProvider 
                name="phone" 
                rules="required|min:8|max:13"
                v-slot="{ errors }">
                <div class="body-2 mb-2 color-purple">
                  Nomor WhatsApp
                </div>
                
                <v-text-field
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  min="0"
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="123 456 789 010"
                  prepend-inner-icon="mdi-phone-in-talk-outline"

                  v-model="form.phone"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>
            </v-form>

            <v-form v-if="selected.auth === 'register'">
              <ValidationProvider 
                name="name" 
                rules="required"
                v-slot="{ errors }">
                <div class="body-2 mb-2 color-purple">
                  Nama Lengkap
                </div>
                
                <v-text-field
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="John Doe"
                  prepend-inner-icon="mdi-account-outline"

                  v-model="form.phone"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider 
                name="phone" 
                rules="required|min:8|max:13"
                v-slot="{ errors }">
                <div class="body-2 mb-2 color-purple">
                  Nomor WhatsApp
                </div>
                
                <v-text-field
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  min="0"
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="123 456 789 010"
                  prepend-inner-icon="mdi-phone-in-talk-outline"

                  v-model="form.phone"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider 
                name="instagram" 
                rules="required"
                v-slot="{ errors }">
                <div class="body-2 mb-2 color-purple">
                  Alamat Instagram (Opsional)
                </div>
                
                <v-text-field
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="@username"
                  prepend-inner-icon="mdi-instagram"

                  v-model="form.instagram"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <div
                class="body-2 line-text-first mb-4">
                *Dapatkan merchandise menarik dari HeHa Sky View apabila kamu mengisi alamat Instagram.
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="dialog.auth = false; dialog.otp = true;"
            x-large
            block
            elevation="0"
            color="#2B3F6C"
            class="body-1 font-weight-bold white--text text-capitalize"
            style="border-radius: 10px;">
            {{ selected.auth === 'login' ? 'Masuk' : 'Daftar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog 
      v-model="dialog.otp"
      persistent>
      <v-card 
        class="py-4"
        style="border-radius: 25px;">
        <v-card-text
          class="px-4">
          <div
            class="mb-12 d-flex align-center">
            <div>
              <v-icon 
                @click="dialog.otp = false; dialog.auth = true;"
                size="26" 
                color="#2B3F6C">
                mdi-arrow-left
              </v-icon>
            </div>

            <v-spacer />
            
            <div>
              <v-img 
                contain
                width="100"
                height="auto"
                alt="heha" 
                :src="require('@/assets/logo/logo_1.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>

            <v-spacer />
          </div>

          <div
            class="title font-weight-bold black--text mb-2">
            Verifikasi Kode OTP
          </div>

          <div
            class="body-2 line-text-first mb-6">
            Kode OTP telah dikirim ke nomor WhatsApp +621234567890. Silahkan cek peasn di WhatsApp kamu. 
          </div>

          <ValidationObserver 
            ref="form">
            <v-form>
              <ValidationProvider 
                name="otp" 
                rules="required|min:6|max:6"
                v-slot="{ errors }">
                <v-otp-input
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  min="0"
                  color="#F38C35"

                  hide-details

                  plain
                  length="6"
                  v-model="form.otp"
                  :error-messages="errors"
                  required>
                </v-otp-input>
              </ValidationProvider>

              <div
                class="body-2 line-text-first mt-2 mb-4 text-center red--text">
                Kode OTP salah!
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        
        <v-card-text
          class="pa-4 pb-2">
          <v-btn
            @click="dialog.otp = false; dialog.info = true;"
            x-large
            block
            elevation="0"
            color="#2B3F6C"
            class="body-1 font-weight-bold white--text text-capitalize mb-4"
            style="border-radius: 10px;">
            Verifikasi
          </v-btn>

          <v-btn
            x-large
            block
            outlined
            elevation="0"
            color="#2B3F6C"
            class="body-1 font-weight-bold color-purple text-capitalize"
            style="border-radius: 10px; opacity: 60%;">
            Kirim Ulang Kode OTP <b>(00:59)</b>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog 
      v-model="dialog.info"
      persistent>
      <v-card 
        class="py-4"
        style="border-radius: 25px;">
        <v-card-text
          class="px-4">
          <div
            class="mb-12 d-flex align-center justify-center">
            <div>
              <v-img 
                contain
                width="100"
                height="auto"
                alt="heha" 
                :src="require('@/assets/logo/logo_1.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
          </div>

          <div
            class="title font-weight-bold text-center color-purple">
            Lengkapi Informasi
          </div>

          <v-divider
            class="mt-3 mb-4"
            style="border-top: 1px solid #e0e0e0;" />

          <ValidationObserver 
            ref="form">
            <v-form>
              <ValidationProvider 
                name="name" 
                rules="required"
                v-slot="{ errors }">
                <div class="body-2 mb-2 color-purple">
                  Nama Lengkap
                </div>
                
                <v-text-field
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="John Doe"
                  prepend-inner-icon="mdi-account-outline"

                  v-model="form.phone"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider 
                name="instagram" 
                rules="required"
                v-slot="{ errors }">
                <div class="body-2 mb-2 color-purple">
                  Alamat Instagram (Opsional)
                </div>
                
                <v-text-field
                  class="no_box"
                  solo
                  color="#F38C35"
                  placeholder="@username"
                  prepend-inner-icon="mdi-instagram"

                  v-model="form.instagram"
                  :error-messages="errors"
                  required>
                </v-text-field>
              </ValidationProvider>

              <div
                class="body-2 line-text-first mb-4">
                *Dapatkan merchandise menarik dari HeHa Sky View apabila kamu mengisi alamat Instagram.
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        
        <v-card-text
          class="pa-4 pb-2">
          <v-btn
            @click="dialog.info = false"
            x-large
            block
            elevation="0"
            color="#2B3F6C"
            class="body-1 font-weight-bold white--text text-capitalize"
            style="border-radius: 10px;">
            Selesai
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import Loader from "~/components/Loader.vue"
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        dialog: {
          auth: false,
          otp: false,
          info: false
        },
        loader: true,
        scroll: true,
        drawer: false,
        selected: {
          menu: 0,
          auth: 'login'
        },
        menu: [
          {
            title: 'Beranda',
            icon: require('@/assets/icon/home.png'),
            link: '/home'
          },
          {
            title: 'Reservasi Tiket',
            icon: require('@/assets/icon/ticket.png'),
            link: '/reservation'
          },
          {
            title: 'Cek Reservasi',
            icon: require('@/assets/icon/check.png'),
            link: '/check'
          },
          {
            title: 'Layanan',
            icon: require('@/assets/icon/signpost.png'),
            link: '/service'
          },
          {
            title: 'Galeri',
            icon: require('@/assets/icon/gallery.png'),
            link: '/gallery'
          },
          {
            title: 'Tentang Kami',
            icon: require('@/assets/icon/building.png'),
            link: '/aboutus'
          },
          {
            title: 'Kontak Kami',
            icon: require('@/assets/icon/call.png'),
            link: '/contact'
          }
        ],
        form: {
          name: '',
          phone: '',
          instagram: '',
          otp: ''
        }
      }
    },
    components: {
      Loader
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      token() {
        return this.$store.state.member.auth.token
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()

      this.loader = false
    },
    beforeCreate() {
      this.loader = true
    },
    created() {
      
    },
    methods: {
      onScroll () {
        const el = document.getElementById('scrolling-techniques-4')
        
        this.scroll = el.scrollTop < window.innerHeight ? true : false

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>