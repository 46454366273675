<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Reservasi Tiket Reguler
              </span>
            </v-card-title>

            <v-card-text
              class="pt-16 mb-4">
              <div
                class="pt-4 d-flex align-center mb-4">
                <div
                  class="title font-weight-bold black--text">
                  Verifikasi Kode OTP
                </div>
              </div>

              <div
                class="body-2 line-text-first">
                Kode OTP telah dikirim ke nomor WhatsApp <b>+621234567890</b>. Silahkan cek peasn di WhatsApp kamu. 
              </div>
            </v-card-text>

            <v-card-text>
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="otp" 
                    rules="required|min:6|max:6"
                    v-slot="{ errors }">
                    <v-otp-input
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                      color="#F38C35"

                      hide-details

                      plain
                      length="6"
                      v-model="form.otp"
                      :error-messages="errors"
                      required>
                    </v-otp-input>
                  </ValidationProvider>

                  <div
                    class="body-2 line-text-first mt-2 mb-4 text-center red--text">
                    Kode OTP salah!
                  </div>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-card
              flat
              color="#f9f9f9"
              min-height="210"
              style="position: fixed;
                    bottom: 0;
                    width: 460px;">
              <v-card-text
                class="pa-4">
                <v-btn
                  to="/reservation/payment/tiketreguler"
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize mb-4"
                  style="border-radius: 10px;">
                  Konfirmasi OTP
                </v-btn>

                <v-btn
                  x-large
                  block
                  outlined
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold color-purple text-capitalize"
                  style="border-radius: 10px; opacity: 60%;">
                  Kirim Ulang Kode OTP <b>(00:59)</b>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        form: {
          otp: ''
        },
        // SEO
        content: {
          url: '/reservation/otp/:slug',
          title: 'Verifikasi Kode OTP Reservasi Tiket Reguler',
          description: 'Verifikasi Kode OTP Reservasi Tiket Reguler HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>