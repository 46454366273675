<template>
  <div>
    <v-container>
      <v-card
        flat
        color="transparent">
        <v-card-text>
          <v-expansion-panels
            flat>
            <v-expansion-panel
              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
              <v-expansion-panel-header
                class="py-2">
                <v-list 
                  flat 
                  dense
                  color="transparent"
                  class="pa-0">
                  <v-list-item
                    class="d-flex align-center px-0">
                    <v-list-item-avatar
                      size="30"
                      class="ma-0 mr-3">
                      <v-img 
                        contain
                        alt="heha" 
                        :src="require('@/assets/icon/lamp.png')"
                        class="ma-auto"
                        style="border-radius: 10px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <div
                        class="title font-weight-bold color-purple">
                        Pengumuman
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div
                  class="pa-4 pt-0">
                  <v-divider
                    class="mb-4"
                    style="border-top: 1px solid #e0e0e0;" />
                
                  <div v-if="Object.keys(data.announcement).length > 0">
                    <div
                      class="body-2 line-text-first font-weight-bold mb-2">
                      {{ data.announcement.announcement_title }}
                    </div>

                    <div
                      class="body-2 line-text-first"
                      v-html="data.announcement.announcement_description.replace(/(<([^>]+)>)/ig, '')">
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-text>
          <v-expansion-panels
            flat>
            <v-expansion-panel
              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
              <v-expansion-panel-header
                class="py-2">
                <v-list 
                  flat 
                  dense
                  color="transparent"
                  class="pa-0">
                  <v-list-item
                    class="d-flex align-center px-0">
                    <v-list-item-avatar
                      size="30"
                      tile
                      class="ma-0 mr-3">
                      <v-img 
                        contain
                        alt="heha" 
                        :src="require('@/assets/icon/card_tick.png')"
                        class="ma-auto"
                        style="border-radius: 10px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <div
                        class="title font-weight-bold color-purple">
                        Cek Status Reservasi Tiket
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div
                  class="pa-4 pt-0">
                  <v-divider
                    class="mb-4"
                    style="border-top: 1px solid #e0e0e0;" />
                
                  <div
                    class="body-1 line-text-first mb-6">
                    Cek reservasi tiket kamu dengan mengisi form dibawah ini
                  </div>

                  <ValidationObserver 
                    ref="form">
                    <v-form>
                      <v-row>
                        <v-col
                          cols="4"
                          class="py-0">
                          <ValidationProvider 
                            name="booking_code" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              class="no_box"
                              solo
                              color="#F38C35"
                              placeholder="Masukkan Kode Booking"

                              hide-details

                              v-model="form.booking_code"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <v-col
                          cols="4"
                          class="py-0">
                          <ValidationProvider 
                            name="phone_number" 
                            rules="required|min:8|max:13"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save"
                              type="number"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              min="0"
                              class="no_box"
                              solo
                              color="#F38C35"
                              placeholder="Masukkan Nomor WhatsApp"

                              hide-details

                              v-model="form.phone_number"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <v-col
                          cols="4"
                          class="py-0">
                          <v-btn
                            :disabled="process.form"
                            :loading="process.form"
                            v-on:keyup.enter="save"
                            @click="save"
                            large
                            block
                            elevation="0"
                            color="#2B3F6C"
                            class="body-1 font-weight-bold white--text text-capitalize"
                            style="border-radius: 8px;">
                            Cek Reservasi Kamu
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-4 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
                      
                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-text
          class="mt-8 py-0">
          <v-row>
            <v-col
              v-for="(item, index) in process.load ? 2 : list"
              :key="index"
              cols="12">
              <div
                class="d-flex align-center mb-3">
                <div
                  class="title font-weight-bold color-purple text-capitalize">
                  Tiket {{ item.ticket_type }}
                </div>
              </div>
              
              <v-row>
                <v-col
                  cols="4">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    type="image">
                    <v-img
                      width="100%"
                      height="260"
                      alt="heha" 
                      :src="item.ticket_image"
                      class="ma-auto pa-2 d-flex align-end"
                      style="border-radius: 15px;">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate 
                            color="#F38C35">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-skeleton-loader>
                </v-col>

                <v-col
                  cols="8">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    type="article, actions">
                    <v-card
                      flat
                      color="transparent">
                      <v-card-text
                        class="title font-weight-bold color-purple d-flex align-center pt-0">
                        <v-avatar 
                          size="26"
                          tile>
                          <v-img
                            contain
                            alt="heha" 
                            :src="require('@/assets/icon/ticket.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate 
                                  color="#F38C35">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                        
                        <span
                          class="ml-3 text-capitalize">
                          Reservasi Tiket {{ item.ticket_type }}
                        </span>
                      </v-card-text>

                      <v-card-text
                        class="py-0">
                        <v-divider
                          class="mb-4"
                          style="border-top: 1px solid #92A6D3;" />

                        <div
                          class="body-1 line-text-second">
                          {{ item.ticket_description }}
                        </div>

                        <div
                          class="title font-weight-bold color-purple mt-6 mb-2">
                          {{ item.ticket_price | price }} / Orang
                        </div>
                      </v-card-text>

                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="6">
                            <v-btn
                              :to="`/reservation/${item.ticket_uuid}`"
                              large
                              block
                              elevation="0"
                              outlined
                              color="#2B3F6C"
                              class="body-1 font-weight-bold color-purple text-capitalize"
                              style="border-radius: 8px;">
                              Detail
                            </v-btn>
                          </v-col>

                          <v-col
                            cols="6">
                            <!-- <v-btn
                              to="/reservation/ticket/tiketreguler"
                              large
                              block
                              elevation="0"
                              color="#2B3F6C"
                              class="body-1 font-weight-bold white--text text-capitalize"
                              style="border-radius: 8px;">
                              Reservasi Tiket
                            </v-btn> -->

                            <v-btn
                              @click="drawer = true"
                              large
                              block
                              elevation="0"
                              color="#2B3F6C"
                              class="body-1 font-weight-bold white--text text-capitalize"
                              style="border-radius: 8px;">
                              Reservasi Tiket
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- <v-card-text
          class="mt-3 py-2">
          <div
            class="d-flex align-center mb-3">
            <div
              class="title font-weight-bold color-purple">
              Tiket Terusan
            </div>
          </div>

          <v-row>
            <v-col
              cols="4">
              <v-img
                width="100%"
                height="260"
                alt="heha" 
                :src="require('@/assets/image/example/maxresdefault.jpg')"
                class="ma-auto pa-2 d-flex align-end"
                style="border-radius: 15px;">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <v-col
              cols="8">
              <v-card
                flat
                color="transparent">
                <v-card-text
                  class="title font-weight-bold color-purple d-flex align-center pt-0">
                  <v-avatar 
                    size="26"
                    tile>
                    <v-img
                      contain
                      alt="heha" 
                      :src="require('@/assets/icon/ticket.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate 
                            color="#F38C35">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                  
                  <span
                    class="ml-3">
                     Reservasi Tiket Reguler + Terusan
                  </span>
                </v-card-text>

                <v-card-text
                  class="py-0">
                  <v-divider
                    class="mb-4"
                    style="border-top: 1px solid #92A6D3;" />

                  <div
                    class="body-1 line-text-second">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, Lorem ipsum dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>

                  <div
                    class="title font-weight-bold color-purple mt-6 mb-2">
                    Rp 20.000 / Orang
                  </div>
                </v-card-text>

                <v-card-text>
                  <v-row>
                    <v-col
                      cols="6">
                      <v-btn
                        to="/reservation/tiketterusan"
                        large
                        block
                        elevation="0"
                        outlined
                        color="#2B3F6C"
                        class="body-1 font-weight-bold color-purple text-capitalize"
                        style="border-radius: 8px;">
                        Detail
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6">

                      <v-btn
                        @click="drawer = true"
                        large
                        block
                        elevation="0"
                        color="#2B3F6C"
                        class="body-1 font-weight-bold white--text text-capitalize"
                        style="border-radius: 8px;">
                        Reservasi Tiket
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text> -->

        <v-card-text
          class="body-1 color-default text-center mt-6">
          Syarat & Ketentuan
        </v-card-text>
      </v-card>
    </v-container>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      height="100%"
      width="40%"
      right
      temporary>
      <v-card
        flat
        class="pa-4">
        <v-card-text
          class="title font-weight-bold color-purple d-flex align-center pt-0 pb-2">
          <div
            class="d-flex align-center">
            <v-avatar 
              size="26"
              tile>
              <v-img
                contain
                alt="heha" 
                :src="require('@/assets/icon/ticket.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
            
            <span
              class="ml-3">
              Reservasi Tiket Reguler (Agen)
            </span>
          </div>

          <v-spacer />

          <div
            @click="drawer = false"
            class="cursor-pointer">
            <v-icon size="26" color="#2B3F6C">mdi-close</v-icon>
          </div>
        </v-card-text>
        
        <v-card-text>
          <v-divider
            style="border-top: 1px solid #2B3F6C; opacity: 50%;" />

          <v-row
            justify="center">
            <v-col
              v-for="(item, index) in menu"
              :key="index"
              cols="2"
              class="my-6 px-0 d-flex align-center justify-center">
              <v-divider
                :style="step.value >= item.value && index > 0 ? 'border-top: 2px dashed #F38C35;' : index > 0 ? 'border-top: 2px dashed #00000020;' : 'border: 0px dashed #FFFFFF;'" />

              <v-avatar
                @click="step.title = item.title; step.value = item.value;"
                size="40"
                tile
                class="cursor-pointer">
                <v-img
                  contain
                  alt="heha" 
                  :src="step.value >= item.value && index > 0 ? item.icon_color : item.icon"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <v-divider
                :style="step.value > item.value && index < menu.length - 1 ? 'border-top: 2px dashed #F38C35;' : index < menu.length - 1 ? 'border-top: 2px dashed #00000020;' : 'border: 0px dashed #FFFFFF;'" />
            </v-col>
          </v-row>

          <div
            class="title font-weight-bold color-purple">
            {{ step.title }}
          </div>

          <div>
            <Step1 v-if="step.value === 1"
              :form="form"
              @step="step = $event" />
            <Step2 v-if="step.value === 2"
              :form="form"
              @step="step = $event" />
            <Step3 v-if="step.value === 3"
              :form="form"
              @step="step = $event" />
            <Step4 v-if="step.value === 4"
              :form="form"
              @step="step = $event" />
            <Step5 v-if="step.value === 5"
              :form="form"
              @step="step = $event" />
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: []
      },
      pagination: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      },
      dialog: {
        type: Object,
        default: {}
      },
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        drawer: false,
        step: {
          title: 'Jadwal Kunjungan',
          value: 1
        },
        menu: [
          {
            title: 'Jadwal Kunjungan',
            icon: require('@/assets/image/reservation_dekstop/ticket.png'),
            icon_color: require('@/assets/image/reservation_dekstop/ticket.png'),
            value: 1
          },
          {
            title: 'Informasi Pemesan',
            icon: require('@/assets/image/reservation_dekstop/user.png'),
            icon_color: require('@/assets/image/reservation_dekstop/user_color.png'),
            value: 2
          },
          {
            title: 'Detail Reservasi',
            icon: require('@/assets/image/reservation_dekstop/book.png'),
            icon_color: require('@/assets/image/reservation_dekstop/book_color.png'),
            value: 3
          },
          {
            title: 'Detail Pembayaran',
            icon: require('@/assets/image/reservation_dekstop/invoice.png'),
            icon_color: require('@/assets/image/reservation_dekstop/invoice_color.png'),
            value: 4
          },
          {
            title: 'Konfirmasi Transfer',
            icon: require('@/assets/image/reservation_dekstop/confirmation.png'),
            icon_color: require('@/assets/image/reservation_dekstop/confirmation_color.png'),
            value: 5
          }
        ],
        data: {
          announcement: {}
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Step1: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/step/step_1.vue'),
      Step2: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/step/step_2.vue'),
      Step3: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/step/step_3.vue'),
      Step4: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/step/step_4.vue'),
      Step5: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/step/step_5.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/announcement`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.data.announcement = response.result
          } 
        })
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let params = this.form

          // SAVE TO REST API
          await this.$axios.$get(`${process.env.PREFIX_PROXY}/booking/history`, { params })
          .then((response) => {

            if (response.code === 200) {
              this.process.form = false
              
              // this.$router.push({ path: '/reservation/check/qrcode/tiketreguler' })
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>