<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Our Services
              </span>
            </v-card-title>
            
            <v-card-text
              class="pt-16">
              <div
                class="mt-4">
                <v-card
                  v-for="(item, index) in list"
                  :key="index"
                  flat
                  :class="index < list.length - 1 ? 'mb-6' : ''"
                  color="transparent">
                  <v-img
                    width="100%"
                    height="200"
                    alt="heha" 
                    :src="item.image"
                    class="ma-auto pa-2 d-flex align-end"
                    style="border-radius: 15px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>

                  <div
                    class="body-1 font-weight-bold black--text mt-6 mb-2">
                    {{ item.title }}
                  </div>

                  <div
                    class="body-2 line-text-first"
                    style="opacity: 70%;">
                    {{ item.desc }}
                  </div>
                </v-card>
              </div>
            </v-card-text>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        list: [
          {
            title: 'Selfie Spot',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            image: require('@/assets/image/example/Spot Photo 1.jpg')
          },
          {
            title: 'Garden Area',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            image: require('@/assets/image/example/Venue2.jpg')
          },
          {
            title: 'Selfie Spot',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            image: require('@/assets/image/example/Spot Photo 2.jpg')
          },
          {
            title: 'Garden Area',
            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            image: require('@/assets/image/example/Tiket Terusan.jpg')
          }
        ],
        // SEO
        content: {
          url: '/service',
          title: 'Our Service',
          description: 'Our Service HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>