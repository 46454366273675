<template>
  <div>
    <v-container>
      <v-card
        flat
        color="transparent">
        <v-card-text>
          <v-expansion-panels
            flat>
            <v-expansion-panel
              style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
              <v-expansion-panel-header
                class="py-2">
                <v-list 
                  flat 
                  dense
                  color="transparent"
                  class="pa-0">
                  <v-list-item
                    class="d-flex align-center px-0">
                    <v-list-item-avatar
                      size="30"
                      class="ma-0 mr-3">
                      <v-img 
                        contain
                        alt="heha" 
                        :src="require('@/assets/icon/lamp.png')"
                        class="ma-auto"
                        style="border-radius: 10px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <div
                        class="title font-weight-bold color-purple">
                        Pengumuman
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div
                  class="pa-4 pt-0">
                  <v-divider
                    class="mb-4"
                    style="border-top: 1px solid #e0e0e0;" />

                  <div v-if="Object.keys(data.announcement).length > 0">
                    <div
                      class="body-2 line-text-first font-weight-bold mb-2">
                      {{ data.announcement.announcement_title }}
                    </div>

                    <div
                      class="body-2 line-text-first"
                      v-html="data.announcement.announcement_description.replace(/(<([^>]+)>)/ig, '')">
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          
          <div
            class="title font-weight-bold color-purple mt-14">
            Detail Tiket Reguler
          </div>

          <div
            class="pt-4">
            <v-img
              width="100%"
              height="312"
              alt="heha" 
              :src="detail.ticket_image"
              class="ma-auto"
              style="border-radius: 15px;">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate 
                    color="#F38C35">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </v-card-text>

        <v-skeleton-loader 
          class="ma-auto"
          :loading="process.load"
          type="image, article, list-item-three-line, actions">
          <div>
            <div v-if="!process.load && Object.keys(detail).length > 0">
              <v-card-text>
                <div
                  class="title font-weight-bold color-purple mb-8">
                  <v-avatar 
                    size="26"
                    tile>
                    <v-img
                      contain
                      alt="heha" 
                      :src="require('@/assets/icon/ticket.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate 
                            color="#F38C35">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                  
                  <span
                    class="ml-2">
                    Reservasi Tiket {{ detail.ticket_type }}
                  </span>
                </div>

                <div
                  class="body-1 line-text-second mb-4">
                  {{ detail.ticket_description }}
                </div>
              </v-card-text>
              
              <v-card-text
                class="py-8">
                <div
                  class="text-h5 font-weight-bold color-purple">
                  {{ detail.ticket_price | price }} / Orang
                </div>
              </v-card-text>

              <v-card-actions
                class="pa-4">
                <v-btn
                  @click="toCookie(detail.ticket_uuid)"
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize"
                  style="border-radius: 10px;">
                  Reservasi
                </v-btn>
              </v-card-actions>

              <v-card-text
                class="body-1 color-default text-center mt-6">
                Syarat & Ketentuan
              </v-card-text>
            </div>
          </div>
        </v-skeleton-loader>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        data: {
          announcement: {}
        },
        form: {
          ticket_allotment_id: '',
          total_ticket: 1,
          ticket_allotment_type: '',
          ticket_allotment_date: '',
          ticket_allotment_total_price: 0,
          user_full_name: '',
          user_wa_number: '',
          user_email: '',
          user_sosmed_account: '',
          addon_items: [],
          payment_method: '',
          voucher_code: '',
          voucher_discount_nominal: 0,
          note: '',
          total_price: 0
        }
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/announcement`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.data.announcement = response.result
          } 
        })
      },
      toCookie (uuid) {
        this.$store.commit('reservation/setForm', this.form)
        Cookie.set('form', JSON.stringify(this.form))

        this.$router.push({ path: `/reservation/ticket/${uuid}` })
      }
    }
  }
</script>