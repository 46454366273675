<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Detail Tiket Reguler
              </span>
            </v-card-title>

            <div
              class="pt-16">
              <div
                class="pt-4">
                <v-img
                  width="100%"
                  height="222"
                  alt="heha" 
                  :src="require('@/assets/image/example/Venue5.jpeg')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>

            <v-card-text
              class="pb-16 mb-12">
              <div
                class="title font-weight-bold color-purple mb-2">
                Reservasi Tiket Reguler
              </div>

              <div
                class="body-2 line-text-first mb-2">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet”
              </div>

              <div
                class="body-2 line-text-first mb-4">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet”
              </div>
            </v-card-text>

            <v-card
              flat
              color="#f9f9f9"
              min-height="180"
              style="position: fixed;
                    bottom: 0;
                    width: 460px;">
              <v-card-text
                class="pt-2 pb-0">
                <div
                  class="title font-weight-bold color-purple">
                  Rp 120.000 / Orang
                </div>
              </v-card-text>

              <v-card-actions
                class="pa-4">
                <v-btn
                  to="/reservation/ticket/tiketreguler"
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize"
                  style="border-radius: 10px;">
                  Reservasi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        // SEO
        content: {
          url: '/reservation/:slug',
          title: 'Detail Reservasi Tiket',
          description: 'Detail Reservasi Tiket HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>