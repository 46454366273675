<template>
  <div>
    <v-card
      flat
      color="transparent"
      style="border-radius: 0px;">
      <v-img
        contain
        width="100%"
        height="auto"
        alt="heha" 
        gradient="to left, rgba(0, 0, 0, .1), rgba(0, 0, 0, .7)"
        :src="require('@/assets/image/example/bg_gallery.png')"
        class="ma-auto d-flex align-center">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#F38C35">
            </v-progress-circular>
          </v-row>
        </template>

        <div
          class="text-h5 font-weight-bold white--text text-center">
          Galeri
        </div>
      </v-img>

      <v-container>
        <v-card-text
          class="body-1 line-text-second text-center black--text mt-8 px-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </v-card-text>
      </v-container>
    </v-card>
    
    <v-container>
      <v-card
        flat
        color="transparent">
        <v-card-text>
          <v-expansion-panels 
            v-model="selected.gallery"
            focusable
            flat
            class="pt-4">
            <v-expansion-panel
              v-for="(item, index) in gallery"
              :key="index"
              style="background-color: transparent;">
              <v-expansion-panel-header
                :color="selected.gallery === index ? '#F38C35' : 'white'"
                :class="selected.gallery === index ? 'white--text' : 'color-purple'"
                class="title font-weight-bold mb-4"
                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                {{ item.title }}

                <template v-slot:actions>
                  <v-icon 
                    :color="selected.gallery === index ? '#FFFFFF' : '#2B3F6C'">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                color="transparent">
                <v-row>
                  <v-col
                    v-for="(item_img, index_img) in item.image"
                    :key="index_img"
                    cols="3"
                    class="pa-2">
                    <v-img
                      @click="selected.zoom = item_img; dialog.zoom = true;"
                      width="100%"
                      height="250"
                      alt="heha" 
                      :src="item_img"
                      class="ma-auto cursor-pointer"
                      style="border-radius: 15px;">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#F38C35">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog 
      v-model="dialog.zoom"
      width="1000">
      <v-img
        width="100%"
        height="auto"
        alt="heha" 
        :src="selected.zoom"
        class="ma-auto"
        style="border-radius: 15px;">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#F38C35">
            </v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      dialog: {
        type: Object,
        default: {}
      },
      selected: {
        type: Object,
        default: {}
      },
      gallery: {
        type: Array,
        default: []
      }
    },
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>