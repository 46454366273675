<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Reservasi Tiket Reguler
              </span>
            </v-card-title>

            <v-card-text
              class="pt-16 mb-4">
              <div
                class="pt-4 d-flex align-center">
                <div
                  class="title font-weight-bold black--text">
                  Jadwal Tiket
                </div>

                <v-spacer />

                <div>
                  <v-chip
                    color="#12BA0E"
                    class="white--text">
                    Tersedia (313)
                  </v-chip>
                </div>
              </div>
            </v-card-text>

            <v-card-text>
              <ValidationObserver 
                ref="form">
                <v-form>
                  <v-menu
                    ref="picker"
                    min-width="290px"
                    max-width="290px"
                    transition="scale-transition"
                    offset-y
                    :nudge-right="40"
                    :close-on-content-click="false"
                    v-model="picker">
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider 
                        name="date" 
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 color-purple mb-2">
                          Tanggal Kunjungan
                        </div>
                        
                        <v-text-field
                          v-on:keyup.enter="save"
                          class="no_box"
                          solo
                          color="#F38C35"
                          readonly
                          placeholder="2022-10-13"
                          prepend-inner-icon="mdi-calendar-month"

                          clearable
                          @click:clear="form.date = ''"
                          v-on="on"
                          v-bind="attrs"

                          v-model="form.date"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </template>

                    <v-date-picker 
                      color="#F38C35"
                      v-model="form.date"
                      @input="picker = false">
                    </v-date-picker>
                  </v-menu>

                  <v-divider 
                    class="mb-7"
                    style="border-top: 1px solid #e0e0e0;" />

                  <ValidationProvider 
                    name="quota" 
                    rules="required|min:8|max:13"
                    v-slot="{ errors }">
                    <div class="body-2 mb-2 color-purple">
                      Jumlah Reservasi Tiket
                    </div>
                    
                    <v-text-field
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                      class="no_box"
                      solo
                      color="#F38C35"
                      placeholder="10"
                      prepend-inner-icon="mdi-ticket-confirmation-outline"

                      v-model="form.quota"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-card
              flat
              color="#f9f9f9"
              min-height="140"
              style="position: fixed;
                    bottom: 0;
                    width: 460px;">
              <v-card-actions
                class="pa-4">
                <v-btn
                  to="/reservation/addons/tiketreguler"
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize"
                  style="border-radius: 10px;">
                  Selanjutnya
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        picker: false,
        form: {
          date: '',
          quota: 0
        },
        // SEO
        content: {
          url: '/reservation/ticket/:slug',
          title: 'Reservasi Tiket Reguler',
          description: 'Reservasi Tiket Reguler HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>