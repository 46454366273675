<template>
  <div>
    <v-container>
      <v-card
        flat
        color="transparent">
        <v-card-title
          class="d-flex align-center pa-3 pt-6"
          style="position: fixed;
                top: 64px;
                width: 100%;
                background: #f9f9f9;">
          <v-btn
            @click="$router.go(-1)"
            icon 
            width="36"
            height="36">
            <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
          </v-btn>

          <span
            class="body-1 font-weight-bold color-purple ml-2">
            Reservasi Tiket Reguler
          </span>
        </v-card-title>

        <v-card-text
          class="pt-16 mb-4">
          <div
            class="pt-4 d-flex align-center mb-4">
            <div
              class="title font-weight-bold black--text">
              Verifikasi Kode OTP
            </div>
          </div>

          <div
            class="body-1 line-text-first">
            Kode OTP telah dikirim ke nomor WhatsApp <b>+621234567890</b>. Silahkan cek peasn di WhatsApp kamu. 
          </div>
        </v-card-text>

        <v-card-text>
          <ValidationObserver 
            ref="form">
            <v-form>
              <ValidationProvider 
                name="otp" 
                rules="required|min:6|max:6"
                v-slot="{ errors }">
                <v-otp-input
                  type="number"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  min="0"
                  color="#F38C35"

                  hide-details

                  plain
                  length="6"
                  v-model="form.otp"
                  :error-messages="errors"
                  required>
                </v-otp-input>
              </ValidationProvider>

              <div
                class="body-2 line-text-first mt-2 mb-4 text-center red--text">
                Kode OTP salah!
              </div>
            </v-form>
          </ValidationObserver>
        </v-card-text>

        <div style="position: relative; max-width: 1135px; margin: auto;">
          <v-card
            flat
            color="#f9f9f9"
            min-height="210"
            style="position: fixed;
                  bottom: 0;
                  max-width: inherit;
                  width: 100%;">
            <v-card-text
              class="pa-4">
              <v-btn
                to="/reservation/payment/tiketreguler"
                x-large
                block
                elevation="0"
                color="#2B3F6C"
                class="body-1 font-weight-bold white--text text-capitalize mb-4"
                style="border-radius: 10px;">
                Konfirmasi OTP
              </v-btn>

              <v-btn
                x-large
                block
                outlined
                elevation="0"
                color="#2B3F6C"
                class="body-1 font-weight-bold color-purple text-capitalize"
                style="border-radius: 10px; opacity: 60%;">
                Kirim Ulang Kode OTP <b>(00:59)</b>
              </v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>