<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 1;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Venue
              </span>
            </v-card-title>

            <v-row
              class="pt-16">
              <v-col
                v-for="(item, index) in process.load ? 6 : list"
                :key="index"
                cols="12"
                class="py-0">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="process.load"
                  type="image, article">
                  <div>
                    <v-card v-if="!process.load && list.length > 0"
                      flat
                      color="transparent">
                      <v-card-text
                        class="pb-0">
                        <v-img
                          width="100%"
                          height="200"
                          alt="heha" 
                          :src="item.venue_image"
                          class="ma-auto"
                          style="border-radius: 15px;">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                color="#F38C35">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card-text>

                      <v-card-text>
                        <div
                          class="body-1 font-weight-bold black--text mb-1">
                          {{ item.venue_title }}
                        </div>

                        <div
                          class="body-2 line-text-first">
                          {{ item.venue_description }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-skeleton-loader>
              </v-col>
            </v-row>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        list: [],
        pagination: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/venue',
          title: 'Venue',
          description: 'Venue HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/venue`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.list = response.result.data
            this.pagination = response.result.meta
          } 
        })
      }
    }
  }
</script>