<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Reservasi Tiket
              </span>
            </v-card-title>
            
            <v-card-text
              class="pt-16">
              <v-expansion-panels
                class="pt-4"
                flat>
                <v-expansion-panel
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                  <v-expansion-panel-header
                    class="py-2">
                    <v-list 
                      flat 
                      dense
                      color="transparent"
                      class="pa-0">
                      <v-list-item
                        class="d-flex align-center px-0">
                        <v-list-item-avatar
                          size="20"
                          class="ma-0 mr-3">
                          <v-img 
                            contain
                            alt="heha" 
                            :src="require('@/assets/icon/lamp.png')"
                            class="ma-auto"
                            style="border-radius: 10px;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  color="#F38C35">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <div
                            class="body-1 font-weight-bold color-purple">
                            Pengumuman
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <div
                      class="pa-4 pt-0">
                      <v-divider
                        class="mb-4"
                        style="border-top: 1px solid #e0e0e0;" />
                    
                      <div
                        class="body-2 line-text-first mb-2">
                        Demi memutus penyebaran virus Covid-19, dengan ini HeHa Sky View mengumumkan pembatasan pengunjung yang terhitung sejak <b>26 September 2020</b>.
                      </div>

                      <div
                        class="body-2 line-text-first">
                        Pemesanan tiket dapat dilakukan <b>1 Hari sebelum kunjungan</b>.
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>

            <v-card-text
              class="mt-8 py-2">
              <v-btn
                @click="dialog.check = true"
                block
                x-large
                color="#F38C35"
                class="text-capitalize white--text border-radius font-weight-bold px-10"
                style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; position: relative; top: -26px;">
                Cek Reservasi Tiket
              </v-btn>

              <div
                class="d-flex align-center mb-3">
                <div
                  class="body-1 font-weight-bold black--text">
                  Tiket Reguler
                </div>

                <v-spacer />

                <nuxt-link
                  to="/reservation/tiketreguler">
                  <span
                    class="body-2 color-default">
                    Detail
                  </span>
                </nuxt-link>
              </div>

              <v-img
                width="100%"
                height="252"
                alt="heha" 
                :src="require('@/assets/image/example/Venue5.jpeg')"
                class="ma-auto pa-2 d-flex align-end"
                style="border-radius: 15px;">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>

                <v-card
                  flat
                  color="#FFFFFFCC"
                  style="border-radius: 15px;">
                  <v-card-text
                    class="body-1 font-weight-bold color-purple d-flex align-center pb-0">
                    <v-avatar 
                      size="20"
                      tile>
                      <v-img
                        contain
                        alt="heha" 
                        :src="require('@/assets/icon/ticket.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    
                    <span
                      class="ml-3">
                      Reservasi Tiket Reguler
                    </span>
                  </v-card-text>

                  <v-divider
                    class="ma-4 my-3"
                    style="border-top: 1px solid #FFFFFFCC;" />

                  <v-card-text
                    class="body-2 line-text-first py-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.
                  </v-card-text>

                  <v-card-actions
                    class="pa-4">
                    <div
                      class="body-1 font-weight-bold color-purple">
                      Rp 20.000 / Orang
                    </div>

                    <v-spacer />

                    <v-btn
                      to="/reservation/ticket/tiketreguler"
                      outlined
                      elevation="0"
                      color="#2B3F6C"
                      class="body-1 font-weight-bold color-purple text-capitalize px-5"
                      style="border-radius: 10px;">
                      Reservasi
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-img>
            </v-card-text>

            <v-card-text
              class="mt-3 py-2">
              <div
                class="d-flex align-center mb-3">
                <div
                  class="body-1 font-weight-bold black--text">
                  Tiket Terusan
                </div>

                <v-spacer />

                <nuxt-link
                  to="/reservation/tiketterusan">
                  <span
                    class="body-2 color-default">
                    Detail
                  </span>
                </nuxt-link>
              </div>

              <v-img
                width="100%"
                height="252"
                alt="heha" 
                :src="require('@/assets/image/example/maxresdefault.jpg')"
                class="ma-auto pa-2 d-flex align-end"
                style="border-radius: 15px;">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>

                <v-card
                  flat
                  color="#FFFFFFCC"
                  style="border-radius: 15px;">
                  <v-card-text
                    class="body-1 font-weight-bold color-purple d-flex align-center pb-0">
                    <v-avatar 
                      size="20"
                      tile>
                      <v-img
                        contain
                        alt="heha" 
                        :src="require('@/assets/icon/ticket.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    
                    <span
                      class="ml-3 one-line">
                      Reservasi Tiket Reguler + Terusan
                    </span>
                  </v-card-text>

                  <v-divider
                    class="ma-4 my-3"
                    style="border-top: 1px solid #FFFFFFCC;" />

                  <v-card-text
                    class="body-2 line-text-first py-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet.
                  </v-card-text>

                  <v-card-actions
                    class="pa-4">
                    <div
                      class="body-1 font-weight-bold color-purple">
                      Rp 20.000 / Orang
                    </div>

                    <v-spacer />

                    <v-btn
                      to="/reservation/ticket/tiketterusan"
                      outlined
                      elevation="0"
                      color="#2B3F6C"
                      class="body-1 font-weight-bold color-purple text-capitalize px-5"
                      style="border-radius: 10px;">
                      Reservasi
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-img>
            </v-card-text>

            <v-card-text
              class="body-2 color-default text-center">
              Syarat & Ketentuan
            </v-card-text>
          </v-card>
        </section>

        <v-dialog 
          v-model="dialog.check">
          <v-card 
            class="py-6"
            style="border-radius: 25px;">
            <v-card-text
              class="pb-0">
              <div
                class="body-1 font-weight-bold color-purple mb-3">
                Cek Reservasi Tiket
              </div>

              <div
                class="body-2 line-text-first">
                Cek reservasi tiket kamu dengan mengisi form dibawah ini
              </div>

              <v-divider
                class="my-6"
                style="border-top: 1px solid #e0e0e0;" />

              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="phone" 
                    rules="required|min:8|max:13"
                    v-slot="{ errors }">
                    <div class="body-2 mb-2 color-purple">
                      Nomor WhatsApp
                    </div>
                    
                    <v-text-field
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                      class="no_box"
                      solo
                      color="#F38C35"
                      placeholder="123 456 789 010"
                      prepend-inner-icon="mdi-phone-in-talk-outline"

                      v-model="form.phone"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-card-actions>
              <v-btn
                to="/reservation/check/qrcode/tiketreguler"
                x-large
                block
                elevation="0"
                color="#2B3F6C"
                class="body-1 font-weight-bold white--text text-capitalize"
                style="border-radius: 10px;">
                Cek Reservasi Tiket Kamu
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          check: false
        },
        form: {
          code: '',
          phone: ''
        },
        // SEO
        content: {
          url: '/reservation',
          title: 'Reservasi Tiket',
          description: 'Reservasi Tiket HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>