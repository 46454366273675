<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <Mobile
            class="mobile py-14" 
            :detail="detail"
            :process="process" />

          <Desktop
            class="desktop py-16"
            :detail="detail"
            :process="process" />
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        detail: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/reservation/:slug',
          title: 'Detail Reservasi Tiket',
          description: 'Detail Reservasi Tiket HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Mobile: () => import(/* webpackPrefetch: true */ '@/components/mobile/reservation/detail.vue'),
      Desktop: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/detail.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ticket/detail/${this.$route.params.slug}`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.detail = response.result
          } 
        })
      }
    }
  }
</script>