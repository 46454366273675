<template>
  <div>
    <v-card
      flat
      color="transparent"
      style="border-radius: 0px;">
      <v-img
        contain
        width="100%"
        height="auto"
        alt="heha" 
        gradient="to left, rgba(0, 0, 0, .1), rgba(0, 0, 0, .7)"
        :src="require('@/assets/image/example/bg_gallery.png')"
        class="ma-auto d-flex align-center">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#F38C35">
            </v-progress-circular>
          </v-row>
        </template>

        <div
          class="text-h5 font-weight-bold white--text text-center">
          Our Services
        </div>
      </v-img>

      <v-container>
        <v-card-text
          class="body-1 line-text-second text-center black--text mt-8 px-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </v-card-text>

        <v-card-text
          class="d-flex align-center pa-0 py-12">
          <no-ssr>
            <swiper 
              class="swiper"
              ref="swiper"
              :options="{ slidesPerView: 'auto' }">
              <swiper-slide 
                v-for="(item, index) in menu" 
                :key="index" 
                style="width: 210px; padding: 10px;">
                <v-card
                  class="a_menu"
                  style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                        border-radius: 15px;">
                  <div>
                    <v-list
                      color="transparent">
                      <v-list-item>
                        <v-list-item-avatar
                          size="60"
                          color="#F0F3F9"
                          class="border-radius">
                          <v-img 
                            contain
                            width="36"
                            height="36"
                            alt="heha" 
                            :src="item.icon"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  size="20"
                                  indeterminate 
                                  color="#F38C35">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <div
                            class="body-2 two-line line-text-first color-purple a_text">
                            {{ item.title }}
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-card>
              </swiper-slide>
            </swiper>
          </no-ssr>
        </v-card-text>

        <v-card-text>
          <div
            class="text-h5 font-weight-bold color-purple text-center mb-8">
            Layanan
          </div>

          <div
            class="body-1 line-text-second text-center black--text my-16 pb-8 px-16">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </div>

          <v-row 
            v-for="(item, index) in process.load ? 6 : list"
            :key="index"
            align="center" 
            class="mirror"
            :class="index < list.length - 1 ? 'mb-16' : ''">
            <v-col 
              cols="12" 
              md="6">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.load"
                type="image">
                <div>
                  <v-img 
                    alt="heha"
                    class="mx-auto"
                    width="100%"
                    height="341"
                    :src="item.service_image"
                    style="border-radius: 30px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-skeleton-loader>
            </v-col>

            <v-col 
              cols="12" 
              md="6">
              <v-skeleton-loader
                class="ma-auto"
                :loading="process.load"
                type="article">
                <div>
                  <v-card 
                    flat
                    color="transparent">
                    <v-card-title 
                      class="text-h5 font-weight-bold mb-4">
                      {{ item.service_title}}
                    </v-card-title>

                    <v-card-text 
                      class="body-1 line-text-second black--text">
                      {{ item.service_description }}
                    </v-card-text>
                  </v-card>
                </div>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      menu: {
        type: Array,
        default: []
      },
      list: {
        type: Array,
        default: []
      },
      pagination: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped>
  .a_menu:hover {
    background: #F38C35;
    border-radius: 10px;
  }

  .a_menu:hover .v-avatar {
    background: #FFFFFF !important;
  }

  .a_menu:hover .a_text {
    font-weight: bold;
    color: #FFFFFF !important;
  }

  .mirror:nth-child(odd) {
    flex-direction: row-reverse;
  }
</style>