<template>
  <div>
    <v-container>
      <v-card
        flat
        color="transparent">
        <v-card-title
          class="d-flex align-center pa-3 pt-6"
          style="position: fixed;
                top: 64px;
                width: 100%;
                background: #f9f9f9;">
          <v-btn
            @click="$router.go(-1)"
            icon 
            width="36"
            height="36">
            <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
          </v-btn>

          <span
            class="body-1 font-weight-bold color-purple ml-2">
            Reservasi Tiket Agen
          </span>
        </v-card-title>

        <v-card-text
          class="pt-16 mb-4">
          <div
            class="pt-4 d-flex align-center">
            <div
              class="title font-weight-bold black--text">
              Tiket Add-Ons
            </div>

            <v-spacer />

            <div
              class="body-1">
              {{ form.addon_items.length }} dipilih
            </div>
          </div>
        </v-card-text>

        <v-row>
          <v-col
            v-for="(item, index) in process.load ? 6 : list"
            :key="index"
            cols="12"
            class="py-0">
            <v-skeleton-loader
              class="ma-auto"
              :loading="process.load"
              type="list-item-three-line">
              <div>
                <div v-if="!process.load && list.length > 0"
                  class="px-4 pb-4">
                  <v-card
                    class="pa-1 pt-0"
                    color="#FFFFFF"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                    <v-card-text
                      class="pa-3">
                      <v-list 
                        flat 
                        color="transparent"
                        class="py-0">
                        <v-list-item-group
                          v-model="form.addon_items"
                          multiple>
                          <template>
                            <v-list-item
                              :value="item"
                              class="pa-0">
                              <template v-slot:default="{ active }">
                                <v-list-item-content
                                  class="pa-0">
                                  <v-list-item-title 
                                      class="body-1 font-weight-bold color-purple mb-2">
                                      {{ item.addon_title }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                      <div
                                        class="body-2 color-purple mb-2">
                                        + {{ item.addon_price | price }} / Tiket
                                      </div>

                                      <div
                                        class="body-2 line-text-first">
                                        {{ item.addon_description }}
                                      </div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-btn
                                    icon>
                                    <v-icon
                                      size="26"
                                      color="#F38C35">
                                      {{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </template>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-skeleton-loader>
          </v-col>
        </v-row>

        <div v-if="message.error.length > 0"
          class="text-center ma-4 d-flex align-center">
          <div
            class="error--text">
            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
            
            <span
              class="ml-1">
              {{ message.error }}
            </span>
          </div>
        </div>

        <div style="position: relative; max-width: 1135px; margin: auto;">
          <v-card
            flat
            color="#f9f9f9"
            min-height="190"
            style="position: fixed;
                  bottom: 0;
                  max-width: inherit;
                  width: 100%;">
            <v-card-text
              class="pa-4">
              <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="save"
                @click="save"
                x-large
                block
                elevation="0"
                color="#2B3F6C"
                class="body-1 font-weight-bold white--text text-capitalize"
                style="border-radius: 10px;">
                Konfirmasi
              </v-btn>

              <div
                class="mt-6 text-center">
                <!-- <nuxt-link
                  :to="`/reservation/buyer/${this.$route.params.slug}`"
                  class="body-1 color-purple">
                  Lewati
                </nuxt-link> -->

                <div
                  @click="toCookie()"
                  class="body-2 color-purple cursor-pointer">
                  Lewati
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      list: {
        type: Array,
        default: []
      },
      pagination: {
        type: Object,
        default: {}
      },
      form: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      toCookie () {
        this.form.addon_items = []
        
        this.$store.commit('reservation/setForm', this.form)
        Cookie.set('form', JSON.stringify(this.form))

        this.$router.push({ path: `/reservation/buyer/${this.$route.params.slug}` })
      },
      async save () {
        this.message.error = ''

        this.process.form = true

        this.$store.commit('reservation/setForm', this.form)
        Cookie.set('form', JSON.stringify(this.form))

        this.$router.push({ path: `/reservation/buyer/${this.$route.params.slug}` })
      }
    }
  }
</script>