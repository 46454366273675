<template>
  <v-bottom-navigation
    grow
    dark
    :shift="false"
    fixed
    background-color="#FFFFFF">
    <v-container
      class="py-1">
      <v-row
        align="center">
        <v-col 
          class="d-flex align-center">
          <div
            class="body-2"
            style="color: #2B3F6C;">
            Copyright © 2022 HeHa Sky View
          </div>

          <v-spacer />
          
          <v-btn
            v-for="(item, index) in sosmed"
            :key="index"
            color="#2B3F6C60"
            width="24"
            height="24"
            class="mx-1 pa-0"
            style="border-radius: 10px;">
            <v-img 
              contain
              width="28"
              height="28"
              alt="heha" 
              :src="item.icon"
              class="ma-auto">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular 
                    size="20"
                    indeterminate 
                    color="#F38C35">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-bottom-navigation>
</template>

<script>
  export default {
    data () {
      return {
        sosmed: [
          {
            icon: require('@/assets/icon/facebook.png'),
            link: '#'
          },
          {
            icon: require('@/assets/icon/instagram.png'),
            link: '#'
          },
          {
            icon: require('@/assets/icon/youtube.png'),
            link: '#'
          }
        ]
      }
    },
    components: {
      
    },
    computed: {
      
    },
    created () {
      
    }
  }
</script>