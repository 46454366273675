<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <Mobile
            class="mobile py-14"
            :list="list"
            :pagination="pagination"
            :process="process"
            :dialog="dialog"
            :form="form" />

          <Desktop
            class="desktop py-16"
            :list="list"
            :pagination="pagination"
            :process="process"
            :dialog="dialog"
            :form="form" />
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          check: false
        },
        form: {
          booking_code: 'H43961195',
          phone_number: '083829382'
        },
        list: [],
        pagination: {},
        process: {
          load: false,
          form: false
        },
        // SEO
        content: {
          url: '/reservation',
          title: 'Reservasi Tiket',
          description: 'Reservasi Tiket HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Mobile: () => import(/* webpackPrefetch: true */ '@/components/mobile/reservation/index.vue'),
      Desktop: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/index.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/ticket`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.list = response.result.data
            this.pagination = response.result.meta
          } 
        })
      }
    }
  }
</script>