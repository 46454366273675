<template>
  <div>
    <v-card
      flat
      color="transparent">
      <v-card-title
        class="d-flex align-center pa-3"
        style="position: fixed;
              top: 100 px;
              width: 460px;
              background: #f9f9f9;
              z-index: 2;">
        <v-btn
          @click="$router.go(-1)"
          icon 
          width="36"
          height="36">
          <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
        </v-btn>

        <span
          class="body-1 font-weight-bold color-purple ml-2">
          Konfirmasi Transfer
        </span>
      </v-card-title>

      <v-card-text
        class="pt-16">
        <div
          class="pt-4">
          <v-card
            flat
            color="#FDE9D8"
            style="border-radius: 15px; border: 1px solid #F38C35; border-style: dashed;">
            <v-card-text
              class="body-2 line-text-first black--text">
              Lakukan pembayaran tiket sebelum 28 Oktober 2022 10:30 WIB atau transaksi pembelian tiket kamu akan dibatalkan secara otomatis melalui sistem.
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>

      <v-card-text
        class="pb-0">
        <v-card
          color="#FFFFFF"
          class="pa-3"
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
          <v-card-title
            class="pa-0 pb-3">
            <div
              class="d-flex align-center">
              <v-img 
                contain
                width="40"
                height="40"
                alt="heha" 
                :src="require('@/assets/image/bank/BCA.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <span
                class="body-2 ml-2">
                BCA
              </span>
            </div>
          </v-card-title>

          <v-card
            flat
            color="#F0F3F9"
            style="border-radius: 15px;">
            <v-card-text>
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold color-purple">
                  1234567890
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold color-default ml-2">
                  copy
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-card-text>

      <v-card-text
        class="pb-2">
        <v-card
          color="#FFFFFF"
          class="pa-3"
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
          <v-card-title
            class="body-2 pa-0 pb-4">
            Total Transfer
          </v-card-title>

          <v-card
            flat
            color="#F0F3F9"
            style="border-radius: 15px;">
            <v-card-text>
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold color-purple">
                  Rp 40.000
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold color-default ml-2">
                  copy
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-card-text>

      <v-card-text
        class="pb-16 mb-8">
        <div
          class="body-1 font-weight-bold black--text mb-3">
          Penting
        </div>

        <div
          class="body-2 line-text-first">
          Detail terkait flow ketika pengguna sudah melakukan pembayaran reservasi tiket, pengguna akan mendapatkan pesan yang dikirim ke nomor whatsapp berisikan link untuk melihat detail pembayaran dan link untuk melihat tiket yang sudah di booking.
        </div>
      </v-card-text>

      <v-card
        to="/reservation/qrcode/tiketreguler"
        flat
        color="#f9f9f9"
        min-height="140"
        style="position: fixed;
              bottom: 0;
              width: 460px;
              z-index: 2;">
        <v-card-text
          class="pa-4">
          <v-btn
            x-large
            block
            elevation="0"
            color="#2B3F6C"
            class="body-1 font-weight-bold white--text text-capitalize mb-4"
            style="border-radius: 10px;">
            Selesai
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>