<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Reservasi Tiket Reguler
              </span>
            </v-card-title>

            <v-card-text
              class="pt-16 mb-4">
              <div
                class="pt-4 d-flex align-center">
                <div
                  class="title font-weight-bold black--text">
                  Informasi Pemesan
                </div>
              </div>
            </v-card-text>

            <v-card-text>
              <ValidationObserver 
                ref="form">
                <v-form>
                  <ValidationProvider 
                    name="name" 
                    rules="required"
                    v-slot="{ errors }">
                    <div class="body-2 mb-2 color-purple">
                      Nama Lengkap
                    </div>
                    
                    <v-text-field
                      class="no_box"
                      solo
                      color="#F38C35"
                      placeholder="John Doe"
                      prepend-inner-icon="mdi-account-outline"

                      v-model="form.phone"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="phone" 
                    rules="required|min:8|max:13"
                    v-slot="{ errors }">
                    <div class="body-2 mb-2 color-purple">
                      Nomor WhatsApp
                    </div>
                    
                    <v-text-field
                      type="number"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      min="0"
                      class="no_box"
                      solo
                      color="#F38C35"
                      placeholder="123 456 789 010"
                      prepend-inner-icon="mdi-phone-in-talk-outline"

                      v-model="form.phone"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="email" 
                    rules="required|email"
                    v-slot="{ errors }">
                    <div class="body-2 mb-2 color-purple">
                      Email (Opsional)
                    </div>
                    
                    <v-text-field
                      class="no_box"
                      solo
                      color="#F38C35"
                      placeholder="example@example.com"
                      prepend-inner-icon="mdi-email-outline"

                      v-model="form.email"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <ValidationProvider 
                    name="instagram" 
                    rules="required"
                    v-slot="{ errors }">
                    <div class="body-2 mb-2 color-purple">
                      Alamat Instagram (Opsional)
                    </div>
                    
                    <v-text-field
                      class="no_box"
                      solo
                      color="#F38C35"
                      placeholder="@username"
                      prepend-inner-icon="mdi-instagram"

                      v-model="form.instagram"
                      :error-messages="errors"
                      required>
                    </v-text-field>
                  </ValidationProvider>

                  <div
                    class="body-2 line-text-first">
                    *Dapatkan merchandise menarik dari HeHa Sky View apabila kamu mengisi alamat Instagram.
                  </div>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-card
              flat
              color="#f9f9f9"
              min-height="140"
              style="position: fixed;
                    bottom: 0;
                    width: 460px;">
              <v-card-actions
                class="pa-4">
                <v-btn
                  to="/reservation/otp/tiketreguler"
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize"
                  style="border-radius: 10px;">
                  Selanjutnya
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        form: {
          name: '',
          phone: '',
          email: '',
          instagram: ''
        },
        // SEO
        content: {
          url: '/reservation/buyer/:slug',
          title: 'Informasi Pemesan Reservasi Tiket Reguler',
          description: 'Informasi Pemesan Reservasi Tiket Reguler HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>