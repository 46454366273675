<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Reservasi Tiket Reguler
              </span>
            </v-card-title>

            <v-card-text
              class="pt-16 mb-4">
              <div
                class="pt-4 d-flex align-center">
                <div
                  class="title font-weight-bold black--text">
                  Tiket Add-Ons
                </div>

                <v-spacer />

                <div>
                  0 dipilih
                </div>
              </div>
            </v-card-text>

            <v-row>
              <v-col
                v-for="(item, index) in addons"
                :key="index"
                class="py-0">
                <div
                  class="px-4 pb-4">
                  <v-card
                    class="pa-1 pt-0"
                    color="#FFFFFF"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                    <v-card-text
                      class="pa-3">
                      <v-list 
                        flat 
                        color="transparent"
                        class="py-0">
                        <v-list-item
                          class="pa-0">
                          <v-list-item-content
                            class="pa-0">
                            <v-list-item-title 
                              class="body-1 font-weight-bold color-purple mb-2">
                              {{ item.title }}
                            </v-list-item-title>

                            <v-list-item-subtitle>
                              <div
                                class="body-2 color-purple mb-2">
                                {{ item.price }}
                              </div>

                              <div
                                class="body-2 line-text-first">
                                {{ item.desc }}
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              @click="item.check = !item.check ? true : false"
                              icon>
                              <v-icon
                                size="26"
                                color="#F38C35">
                                {{ item.check ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>

            <v-card
              flat
              color="#f9f9f9"
              min-height="190"
              style="position: fixed;
                    bottom: 0;
                    width: 460px;">
              <v-card-text
                class="pa-4">
                <v-btn
                  to="/reservation/buyer/tiketreguler"
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize"
                  style="border-radius: 10px;">
                  Konfirmasi
                </v-btn>

                <div
                  class="mt-6 text-center">
                  <nuxt-link
                    to="/reservation/buyer/tiketreguler"
                    class="body-2 color-purple">
                    Lewati
                  </nuxt-link>
                </div>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        addons: [
          {
            title: 'Snack',
            price: '+ Rp 10.000 / Tiket',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            check: false
          },
          {
            title: 'Photo',
            price: '+ Rp 10.000 / Tiket',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            check: false
          },
          {
            title: 'Other',
            price: '+ Rp 10.000 / Tiket',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            check: false
          }
        ],
        // SEO
        content: {
          url: '/reservation/addons/:slug',
          title: 'Addons Reservasi Tiket Reguler',
          description: 'Addons Reservasi Tiket Reguler HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>