<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <Mobile
            class="mobile py-14"
            :menu="menu"
            :list="list"
            :pagination="pagination"
            :process="process" />

          <Desktop
            class="desktop py-16"
            :menu="menu"
            :list="list"
            :pagination="pagination"
            :process="process" />
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
         menu: [
          {
            title: 'Menu Resto',
            icon: require('@/assets/icon/WheelChair 1.png')
          },
          {
            title: 'Wheelchair Access',
            icon: require('@/assets/icon/WheelChair 2.png')
          },
          {
            title: 'Musholla',
            icon: require('@/assets/icon/WheelChair 3.png')
          },
          {
            title: 'Souvenir Shop',
            icon: require('@/assets/icon/WheelChair 4.png')
          },
          {
            title: 'Parking Area',
            icon: require('@/assets/icon/WheelChair 5.png')
          }
        ],
        list: [],
        pagination: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/service',
          title: 'Our Service',
          description: 'Our Service HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Mobile: () => import(/* webpackPrefetch: true */ '@/components/mobile/service.vue'),
      Desktop: () => import(/* webpackPrefetch: true */ '@/components/desktop/service.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
     mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/service`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.list = response.result.data
            this.pagination = response.result.meta
          } 
        })
      }
    }
  }
</script>