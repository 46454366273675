<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <Mobile
            class="mobile py-14"
            :dialog="dialog"
            :selected="selected"
            :bank="bank"
            :guide="guide"
            :form="form"
            :process="process" />

          <Desktop
            class="desktop py-16"
            :dialog="dialog"
            :selected="selected"
            :bank="bank"
            :guide="guide"
            :form="form"
            :process="process" />
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dialog: {
          bank: false
        },
        selected: {
          bank: null,
          guide: null
        },
        bank: [
          // {
          //   title: 'BCA',
          //   icon: require('@/assets/image/bank/BCA.png')
          // },
          // {
          //   title: 'Mandiri',
          //   icon: require('@/assets/image/bank/MANDIRI.png')
          // },
          // {
          //   title: 'BRI',
          //   icon: require('@/assets/image/bank/BRI.png')
          // },
          // {
          //   title: 'BNI',
          //   icon: require('@/assets/image/bank/BNI.png')
          // },
          // {
          //   title: 'CIMB Niaga',
          //   icon: require('@/assets/image/bank/CIMB.png')
          // }
          {
            title: 'QRIS',
            icon: require('@/assets/image/bank/QRIS.png'),
            value: 'virtual_account'
          }
        ],
        guide: [
          // {
          //   title: 'M-Banking BCA',
          //   desc: [
          //     'Buka aplikasi BCA mobile, pilih menu <b>“Transfer”</b>',
          //     'Pilih menu <b>“Virtual Account”</b>',
          //     'Masukkan nomor <b>BCA Virtual Account</b> dan klik “Kirim”',
          //     'Cek nominal yang muncul dan klik “Lanjut”',
          //     'Konfirmasi detail transaksi dan klik “Lanjut”',
          //     'Masukkan PIN BCA mobile',
          //     'Transaksi Berhasil'
          //   ]
          // },
          // {
          //   title: 'ATM BCA',
          //   desc: [
          //     'Masukkan Kartu <b>ATM</b> dan <b>PIN ATM BCA</b>',
          //     'Pilih menu “Penarikan Tunai/Transaksi Lainnya”',
          //     'Pilih menu “Transaksi Lainnya”',
          //     'Pilih menu “Transfer”',
          //     'Pilih menu “Ke Rek <b>BCA Virtual Account</b>”',
          //     'Masukkan nomor <b>BCA Virtual Account</b> dan klik “Benar”',
          //     'Cek detail transaksi dan pilih <b>“Ya”</b>',
          //     'Transaksi Selesai'
          //   ]
          // }

          {
            title: 'QRIS',
            desc: [
              'Unduh  <b>“QR Code”</b> di atas dengan cara tahan gambar lalu simpan',
              'Buka OVO, Gojek, Dana, Link Aja, atau aplikasi mobile-banking yang Anda miliki',
              'Pilih opsi bayar lalu unggah QR Code melalui menu di kanan atas',
              'Input jumlah nominal yang ingin dibayarkan',
              'Masukkan nama lengkap Anda lalu esekusi pembayaran'
            ]
          }
        ],
        form: {
          ticket_allotment_id: '',
          total_ticket: 1,
          ticket_allotment_type: '',
          ticket_allotment_date: '',
          ticket_allotment_total_price: 0,
          user_full_name: '',
          user_wa_number: '',
          user_email: '',
          user_sosmed_account: '',
          addon_items: [],
          payment_method: '',
          voucher_code: '',
          voucher_discount_nominal: 0,
          note: '',
          total_price: 0
        },
        process: {
          form: false
        },
        // SEO
        content: {
          url: '/reservation/invoice/:slug',
          title: 'Detail Pembayaran Reservasi Tiket Reguler',
          description: 'Detail Pembayaran Reservasi Tiket Reguler HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Mobile: () => import(/* webpackPrefetch: true */ '@/components/mobile/reservation/invoice.vue'),
      Desktop: () => import(/* webpackPrefetch: true */ '@/components/desktop/reservation/invoice.vue')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      if (Cookie.get('form') !== undefined) {
        this.$store.commit('reservation/setForm', JSON.parse(Cookie.get('form')))
  
        this.form = JSON.parse(Cookie.get('form'))
      }
    },
    methods: {
      
    }
  }
</script>