<template>
  <div>
    <v-card
      flat
      color="transparent">
      <v-card-title
        class="d-flex align-center pa-3"
        style="position: fixed;
              top: 100 px;
              width: 460px;
              background: #f9f9f9;
              z-index: 2;">
        <v-btn
          @click="$router.go(-1)"
          icon 
          width="36"
          height="36">
          <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
        </v-btn>

        <span
          class="body-1 font-weight-bold color-purple ml-2">
          Reservasi Tiket Agen
        </span>
      </v-card-title>

      <v-card-text
        class="pt-16 mb-4">
        <div
          class="pt-4 d-flex align-center">
          <div
            class="title font-weight-bold black--text">
            Informasi Pemesan
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <ValidationObserver 
          ref="form">
          <v-form>
            <ValidationProvider 
              name="user_full_name" 
              rules="required"
              v-slot="{ errors, valid }">
              <div class="body-2 mb-2 color-purple">
                Nama Lengkap
              </div>
              
              <v-text-field
                v-on:keyup.enter="save"
                class="no_box"
                solo
                color="#F38C35"
                placeholder="John Doe"
                prepend-inner-icon="mdi-account-outline"

                v-model="form.user_full_name"
                :success="valid"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <ValidationProvider 
              name="user_wa_number" 
              rules="required|min:8|max:13"
              v-slot="{ errors, valid }">
              <div class="body-2 mb-2 color-purple">
                Nomor WhatsApp
              </div>
              
              <v-text-field
                v-on:keyup.enter="save"
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                min="0"
                class="no_box"
                solo
                color="#F38C35"
                placeholder="123 456 789 010"
                prepend-inner-icon="mdi-phone-in-talk-outline"

                v-model="form.user_wa_number"
                :success="valid"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <ValidationProvider 
              name="user_email" 
              rules="email"
              v-slot="{ errors, valid }">
              <div class="body-2 mb-2 color-purple">
                Email (Opsional)
              </div>
              
              <v-text-field
                v-on:keyup.enter="save"
                class="no_box"
                solo
                color="#F38C35"
                placeholder="example@example.com"
                prepend-inner-icon="mdi-email-outline"

                v-model="form.user_email"
                :success="form.user_email !== '' && valid"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <ValidationProvider 
              name="user_sosmed_account" 
              v-slot="{ errors, valid }">
              <div class="body-2 mb-2 color-purple">
                Alamat Instagram (Opsional)
              </div>
              
              <v-text-field
                v-on:keyup.enter="save"
                class="no_box"
                solo
                color="#F38C35"
                placeholder="@username"
                prepend-inner-icon="mdi-instagram"

                v-model="form.user_sosmed_account"
                :success="form.user_sosmed_account !== '' && valid"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <div
              class="body-2 line-text-first">
              *Dapatkan merchandise menarik dari HeHa Sky View apabila kamu mengisi alamat Instagram.
            </div>
          </v-form>
        </ValidationObserver>

        <div v-if="message.error.length > 0"
          class="text-center my-4 d-flex align-center">
          <div
            class="error--text">
            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
            
            <span
              class="ml-1">
              {{ message.error }}
            </span>
          </div>
        </div>
      </v-card-text>

      <v-card
        flat
        color="#f9f9f9"
        min-height="140"
        style="position: fixed;
              bottom: 0;
              width: 460px;">
        <v-card-actions
          class="pa-4">
          <v-btn
            :disabled="process.form"
            :loading="process.form"
            v-on:keyup.enter="save"
            @click="save"
            x-large
            block
            elevation="0"
            color="#2B3F6C"
            class="body-1 font-weight-bold white--text text-capitalize"
            style="border-radius: 10px;">
            Selanjutnya
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          this.$store.commit('reservation/setForm', this.form)
          Cookie.set('form', JSON.stringify(this.form))

          this.$router.push({ path: `/reservation/payment/${this.$route.params.slug}` })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>