<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text
              class="text-center">
              <v-carousel 
                v-model="selected.banner"
                height="252"
                hide-delimiters
                style="border-radius: 15px;">
                <v-carousel-item 
                  v-for="(item, index) in banner"
                  :key="index"
                  reverse-transition="fade-transition"
                  transition="fade-transition">
                  <v-img 
                    width="100%"
                    height="252"
                    alt="heha" 
                    :src="item.image"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          color="#F38C35">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-carousel-item>
              </v-carousel>

              <v-btn
                to="/reservation"
                x-large
                color="#F38C35"
                class="text-capitalize white--text border-radius font-weight-bold px-10"
                style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; position: relative; top: -26px;">
                Reservasi Tiket Sekarang
              </v-btn>

              <v-card
                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                      border-radius: 15px;">
                <no-ssr>
                  <swiper 
                    class="swiper"
                    ref="swiper"
                    :options="{ slidesPerView: 'auto' }">
                    <swiper-slide 
                      v-for="(item, index) in menu" 
                      :key="index" 
                      class="py-2" 
                      :class="index < 1 ? 'px-2' : index < menu.length ? 'pr-2' : 'px-2'"
                      style="width: auto;">
                      <v-card
                        flat
                        width="50"
                        height="85"
                        class="d-flex justify-center pa-2 a_menu">
                        <div>
                          <v-avatar
                            size="50"
                            color="#F0F3F9"
                            class="ma-auto d-flex align-center border-radius">
                            <v-img 
                              contain
                              width="26"
                              height="26"
                              alt="heha" 
                              :src="item.icon"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    color="#F38C35">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>

                          <div
                            class="mt-2 color-grey line-text-first a_text"
                            style="font-size: 10px;">
                            {{ item.title }}
                          </div>
                        </div>
                      </v-card>
                    </swiper-slide>
                  </swiper>
                </no-ssr>
              </v-card>
            </v-card-text>

            <v-card-text>
              <div
                class="body-1 font-weight-bold black--text mb-2">
                Lokasi
              </div>

              <v-card v-if="Object.keys(currentPosition).length > 0"
                class="pa-1 mb-6"
                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                      border-radius: 15px;">
                <gmap-map
                  ref="gmap"
                  :center="center"
                  :zoom="16"
                  :scrollwheel="false"
                  style="width:100%; height: 200px;">
                  <gmap-marker
                    :position="currentPosition.latlng"
                    :icon="currentPosition.icon">
                  </gmap-marker>
                </gmap-map>
              </v-card>

              <div>
                <v-img 
                  contain
                  width="26"
                  height="26"
                  alt="heha" 
                  :src="require('@/assets/icon/marker.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="body-2 black--text text-center my-3">
                  Jl. Dlingo-Patuk No.2, Bukit, Patuk, Gunung Kidul Regency, Special Region Of Yogyakarta 55862
                </div>

                <v-row>
                  <v-col
                    cols="6">
                    <v-card
                      flat
                      color="#F0F3F9"
                      class="pa-4"
                      style="border-radius: 15px;">
                      <v-img 
                        contain
                        width="100%"
                        height="26"
                        alt="heha" 
                        :src="require('@/assets/icon/call_color.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <div
                        class="caption black--text mt-2 text-center one-line">
                        0812-1700-4900
                      </div>
                    </v-card>
                  </v-col>

                  <v-col
                    cols="6">
                    <v-card
                      flat
                      color="#F0F3F9"
                      class="pa-4"
                      style="border-radius: 15px;">
                      <v-img 
                        contain
                        width="100%"
                        height="26"
                        alt="heha" 
                        :src="require('@/assets/icon/email.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              size="20"
                              indeterminate 
                              color="#F38C35">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <div
                        class="caption black--text mt-2 text-center one-line">
                        hehaskyview@gmail.com
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-card-text 
              class="py-0">
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold black--text">
                  Venue
                </div>

                <v-spacer />

                <nuxt-link
                  to="/venue">
                  <span
                    class="body-2 color-default">
                    Selengkapnya
                  </span>
                </nuxt-link>
              </div>
            </v-card-text>

            <no-ssr>
              <swiper 
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide 
                  v-for="(item, index) in venue" 
                  :key="index" 
                  class="py-3" 
                  :class="index < 1 ? 'px-4' : index < venue.length ? 'pr-4' : 'px-4'"
                  style="width: auto;">
                  <v-card
                    class="pa-1"
                    color="#FFFFFF"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;"
                    width="200">
                    <v-img
                      width="100%"
                      height="125"
                      alt="heha" 
                      :src="item.image"
                      class="ma-auto"
                      style="border-radius: 15px;">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#F38C35">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <v-card-text
                      class="pa-3">
                      <div
                        class="body-1 font-weight-bold black--text mb-1">
                        {{ item.title }}
                      </div>

                      <div
                        class="body-2">
                        {{ item.desc }}
                      </div>
                    </v-card-text>
                  </v-card>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <v-card-text>
              <div
                class="d-flex align-center mb-4">
                <div
                  class="body-1 font-weight-bold black--text">
                  Galeri
                </div>

                <v-spacer />

                <nuxt-link
                  to="/gallery">
                  <span
                    class="body-2 color-default">
                    Selengkapnya
                  </span>
                </nuxt-link>
              </div>

              <v-img
                width="100%"
                height="252"
                alt="heha" 
                :src="gallery[selected.gallery]"
                class="ma-auto"
                style="border-radius: 15px;">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card-text>

            <no-ssr>
              <swiper 
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide 
                  v-for="(item, index) in gallery" 
                  :key="index" 
                  :class="index < 1 ? 'px-4' : index < gallery.length ? 'pr-4' : 'px-4'"
                  style="width: auto;">
                  <v-card
                    color="#FFFFFF"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;"
                    width="110">
                    <v-img
                      width="100%"
                      height="80"
                      alt="heha" 
                      :src="item"
                      class="ma-auto"
                      style="border-radius: 15px;">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            color="#F38C35">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-card>
                </swiper-slide>
              </swiper>
            </no-ssr>

            <v-card-text
              class="mt-3 pb-0">
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold black--text">
                  Testimoni
                </div>

                <v-spacer />

                <nuxt-link
                  to="/testimony">
                  <span
                    class="body-2 color-default">
                    Lihat semua
                  </span>
                </nuxt-link>
              </div>
            </v-card-text>

            <no-ssr>
              <swiper 
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide 
                  v-for="(item, index) in testimony" 
                  :key="index" 
                  class="py-3" 
                  :class="index < 1 ? 'px-4' : index < testimony.length ? 'pr-4' : 'px-4'"
                  style="width: auto;">
                  <v-card
                    class="pa-1 pt-0"
                    color="#FFFFFF"
                    style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;"
                    width="300">
                    <v-card-text
                      class="pa-3">
                      <v-list 
                        flat 
                        color="transparent"
                        class="pt-1 pb-3">
                        <v-list-item
                          class="pa-0">
                          <v-list-item-avatar
                            color="#F0F3F9"
                            size="50"
                            class="border-radius my-0">
                            <v-img 
                              contain
                              width="26"
                              height="26"
                              alt="heha" 
                              :src="require('@/assets/icon/user.png')"
                              class="ma-auto"
                              style="border-radius: 10px;">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    indeterminate 
                                    color="#F38C35">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item-avatar>

                          <v-list-item-content
                            class="pa-0">
                            <v-list-item-title 
                              class="body-1 font-weight-bold mb-2">
                              {{ item.name }}
                            </v-list-item-title>

                            <v-list-item-subtitle>
                              {{ item.date }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <div
                        class="body-2"
                        style="max-width: 277px;">
                        {{ item.desc }}
                      </div>
                    </v-card-text>
                  </v-card>
                </swiper-slide>
              </swiper>
            </no-ssr>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        selected: {
          banner: 0,
          gallery: 0
        },
        banner: [
          {
            title: 'Best View in Yogyakarta.',
            desc: 'Corak warna jingga ketika matahari terbenam menyuguhkan pesonanya. Tempat terbaik menikmati sunset di Jogja ala HeHa Sky View',
            image: require('@/assets/image/example/Banner_Slide_Unofficial_1.jpg')
          },
          {
            title: 'Best View in Yogyakarta.',
            desc: 'Corak warna jingga ketika matahari terbenam menyuguhkan pesonanya. Tempat terbaik menikmati sunset di Jogja ala HeHa Sky View',
            image: require('@/assets/image/example/Banner_Slide_Unofficial_2.jpg')
          },
          {
            title: 'Best View in Yogyakarta.',
            desc: 'Corak warna jingga ketika matahari terbenam menyuguhkan pesonanya. Tempat terbaik menikmati sunset di Jogja ala HeHa Sky View',
            image: require('@/assets/image/example/Banner_Slide_Unofficial_3.jpg')
          }
        ],
        menu: [
          {
            title: 'Menu Resto',
            icon: require('@/assets/icon/WheelChair 1.png')
          },
          {
            title: 'Wheelchair Access',
            icon: require('@/assets/icon/WheelChair 2.png')
          },
          {
            title: 'Musholla',
            icon: require('@/assets/icon/WheelChair 3.png')
          },
          {
            title: 'Souvenir Shop',
            icon: require('@/assets/icon/WheelChair 4.png')
          },
          {
            title: 'Parking Area',
            icon: require('@/assets/icon/WheelChair 5.png')
          }
        ],
        center: {
          lat: -7.849519591217892,
          lng: 110.47830746200785
        },
        currentPosition: {},
        venue: [
          {
            title: 'Selfie Spot',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            image: require('@/assets/image/example/Spot Photo 1.jpg')
          },
          {
            title: 'Garden Area',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            image: require('@/assets/image/example/Venue2.jpg')
          },
          {
            title: 'Selfie Spot',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            image: require('@/assets/image/example/Spot Photo 2.jpg')
          },
          {
            title: 'Garden Area',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            image: require('@/assets/image/example/Tiket Terusan.jpg')
          }
        ],
        gallery: [
          require('@/assets/image/example/Venue1.jpg'),
          require('@/assets/image/example/Venue2.jpg'),
          require('@/assets/image/example/Venue3.jpg'),
          require('@/assets/image/example/Venue4.jpeg'),
          require('@/assets/image/example/Venue5.jpeg')
        ],
        testimony: [
          {
            name: 'Vebita',
            date: '10 Oktober 2022',
            desc: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet”'
          },
          {
            name: 'Cherly',
            date: '29 Oktober 2022',
            desc: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet”'
          },
          {
            name: 'John Doe',
            date: '02 November 2022',
            desc: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet”'
          },
          {
            name: 'Alex',
            date: '18 November 2022',
            desc: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet”'
          }
        ],
        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'Beranda HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.$gmapApiPromiseLazy().then(() => {
        this.currentPosition = {
          latlng: {
            lat: -7.849519591217892,
            lng: 110.47830746200785
          },
          icon: {
            url: require('@/assets/image/location_pin.png'),
            scaledSize: new google.maps.Size(50, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(25, 50)
          }
        }
      })
    },
    methods: {
      
    }
  }
</script>

<style scoped>
  .a_menu:hover {
    background: #F0F3F9;
    border-radius: 10px;
  }

  .a_menu:hover .v-avatar {
    background: #FFFFFF !important;
  }

  .a_menu:hover .a_text {
    font-weight: bold;
    color: #000000;
  }
</style>