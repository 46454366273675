<template>
  <div>
    <v-card
      flat
      height="calc(100vh - 270px)"
      color="transparent">
      <v-card-text
        class="px-0 mt-6">
        <ValidationObserver v-if="!btn_addons"
          ref="form">
          <v-form>
            <v-menu
              ref="picker"
              min-width="290px"
              max-width="290px"
              transition="scale-transition"
              offset-y
              :nudge-right="40"
              :close-on-content-click="false"
              v-model="picker">
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider 
                  name="date" 
                  rules="required"
                  v-slot="{ errors, valid }">
                  <div class="body-2 color-purple mb-2">
                    Tanggal Kunjungan
                  </div>
                  
                  <v-text-field
                    v-on:keyup.enter="save"
                    class="no_box"
                    solo
                    color="#F38C35"
                    readonly
                    placeholder="2022-10-13"
                    prepend-inner-icon="mdi-calendar-month"

                    clearable
                    @click:clear="form.date = ''"
                    v-on="on"
                    v-bind="attrs"

                    v-model="form.date"
                    :success="valid"
                    :error-messages="errors"
                    required>
                  </v-text-field>
                </ValidationProvider>
              </template>

              <v-date-picker 
                color="#F38C35"
                v-model="form.date"
                @input="picker = false">
              </v-date-picker>
            </v-menu>

            <v-divider 
              class="mb-7"
              style="border-top: 1px solid #e0e0e0;" />

            <ValidationProvider 
              name="quota" 
              rules="required|min:8|max:13"
              v-slot="{ errors }">
              <div class="body-2 mb-2 color-purple">
                Jumlah Reservasi Tiket
              </div>
              
              <v-text-field
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                min="0"
                class="no_box"
                solo
                color="#F38C35"
                placeholder="10"
                prepend-inner-icon="mdi-ticket-confirmation-outline"

                v-model="form.quota"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>
          </v-form>
        </ValidationObserver>

        <v-row v-if="btn_addons">
          <v-col
            v-for="(item, index) in addons"
            :key="index"
            cols="12"
            class="py-0">
            <div
              class="pb-4">
              <v-card
                class="pa-1 pt-0"
                color="#FFFFFF"
                style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                <v-card-text
                  class="pa-3">
                  <v-list 
                    flat 
                    color="transparent"
                    class="py-0">
                    <v-list-item
                      class="pa-0">
                      <v-list-item-content
                        class="pa-0">
                        <v-list-item-title 
                          class="body-1 font-weight-bold color-purple mb-2">
                          {{ item.title }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          <div
                            class="body-2 color-purple mb-2">
                            {{ item.price }}
                          </div>

                          <div
                            class="body-2 line-text-first">
                            {{ item.desc }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          @click="item.check = !item.check ? true : false"
                          icon>
                          <v-icon
                            size="26"
                            color="#F38C35">
                            {{ item.check ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <div
        style="position: absolute;
              bottom: 0;
              max-width: inherit;
              width: 100%;">
        <v-btn v-if="!btn_addons"
          @click="btn_addons = true"
          x-large
          block
          elevation="0"
          color="#2B3F6C"
          class="body-1 font-weight-bold white--text text-capitalize"
          style="border-radius: 10px;">
          Selanjutnya
        </v-btn>

        <v-btn v-if="btn_addons"
          @click="$emit('step', {
            title: 'Informasi Pemesan',
            value: 2
          })"
          x-large
          block
          elevation="0"
          color="#2B3F6C"
          class="body-1 font-weight-bold white--text text-capitalize mb-4"
          style="border-radius: 10px;">
          Konfirmasi
        </v-btn>

        <v-btn v-if="btn_addons"
          @click="$emit('step', {
            title: 'Informasi Pemesan',
            value: 2
          })"
          x-large
          block
          outlined
          elevation="0"
          color="#2B3F6C"
          class="body-1 font-weight-bold color-purple text-capitalize"
          style="border-radius: 10px;">
          Lewati
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    props: {
      form: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        picker: false,
        btn_addons: false,
        addons: [
          {
            title: 'Snack',
            price: '+ Rp 10.000 / Tiket',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            check: false
          },
          {
            title: 'Photo',
            price: '+ Rp 10.000 / Tiket',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            check: false
          },
          {
            title: 'Other',
            price: '+ Rp 10.000 / Tiket',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            check: false
          }
        ]
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>