<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex justify-center body-1 font-weight-bold black--text mt-3 mb-1">
              QR Code Tiket Reguler
            </v-card-title>

            <v-card-subtitle
              class="body-2 text-center mb-5">
              Tiket masuk reguler HeHa Sky View
            </v-card-subtitle>

            <v-card-text>
              <v-card
                flat
                color="#F0F3F9"
                style="border-radius: 15px;">
                <v-card-text
                  class="text-center">
                  <div
                    class="body-2 font-weight-bold color-purple">
                    Dani Irawan
                  </div>

                  <v-divider
                    class="my-4"
                    style="border-bottom: 1px dashed #92A6D3;" />

                  <div>
                    10 Desember 2022
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>

            <v-card-text
              class="text-center">
              <v-img 
                contain
                width="225"
                height="225"
                alt="heha" 
                :src="require('@/assets/image/example/qr_code.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 black--text mt-3">
                7 3 6 9 1 2 3
              </div>
            </v-card-text>

            <v-card-text
              class="body-2 line-text-first text-center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            </v-card-text>

            <v-card
              flat
              color="#f9f9f9"
              min-height="140"
              style="position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 2;">
              <v-card-text
                class="pa-4">
                <v-btn
                  x-large
                  block
                  elevation="0"
                  color="#2B3F6C"
                  class="body-1 font-weight-bold white--text text-capitalize mb-4"
                  style="border-radius: 10px;">
                  Simpan QR
                </v-btn>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        // SEO
        content: {
          url: '/reservation/qrcode/:slug',
          title: 'QR Code Reservasi Tiket Reguler',
          description: 'QR Code Reservasi Tiket Reguler HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>