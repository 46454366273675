<template>
  <div>
    <v-card
      flat
      color="transparent">
      <v-card-title
        class="d-flex align-center pa-3"
        style="position: fixed;
              top: 100 px;
              width: 460px;
              background: #f9f9f9;
              z-index: 2;">
        <v-btn
          @click="$router.go(-1)"
          icon 
          width="36"
          height="36">
          <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
        </v-btn>

        <span
          class="body-1 font-weight-bold color-purple ml-2">
          Reservasi Tiket Agen
        </span>
      </v-card-title>

      <v-card-text
        class="pt-16 pb-0">
        <div
          class="pt-4 d-flex align-center">
          <div
            class="title font-weight-bold black--text">
            Detail Pembayaran
          </div>
        </div>
      </v-card-text>

      <v-card-text
        class="pb-0">
        <v-card
          color="#FFFFFF"
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
          <v-card-title
            class="d-flex align-center pb-3">
            <div
              class="body-2 font-weight-bold color-purple">
              {{ form.user_full_name }}
            </div>

            <v-spacer />

            <div>
              <nuxt-link
                :to="`/reservation/buyer/${$route.params.slug}`">
                <v-img 
                  contain
                  width="20"
                  height="20"
                  alt="heha" 
                  :src="require('@/assets/icon/edit.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>
          </v-card-title>

          <v-divider
            class="mx-4"
            style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />

          <v-card-text>
            <div
              class="d-flex align-center mb-2">
              <div>
                <v-img 
                  contain
                  width="18"
                  height="18"
                  alt="heha" 
                  :src="require('@/assets/icon/call_color.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div
                class="body-2 ml-2">
                {{ form.user_wa_number }}
              </div>
            </div>

            <div
              class="d-flex align-center">
              <div>
                <v-img 
                  contain
                  width="18"
                  height="18"
                  alt="heha" 
                  :src="require('@/assets/icon/email.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div
                class="body-2 ml-2">
                {{ form.user_email === '' ? '-' : form.user_email }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text>
        <v-card
          color="#FFFFFF"
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
          <v-card-text>
            <!-- <div
              class="d-flex align-center">
              <div
                class="body-2">
                Kode Booking
              </div>

              <v-spacer />

              <div
                class="body-2 font-weight-bold black--text">
                7369123
              </div>
            </div>

            <div
              class="d-flex align-center my-2">
              <div
                class="body-2">
                Nomor Tiket
              </div>

              <v-spacer />

              <div
                class="body-2 font-weight-bold black--text">
                hsk00006
              </div>
            </div> -->

            <div
              class="d-flex align-center mb-2">
              <div
                class="body-2">
                Tiket
              </div>

              <v-spacer />

              <div
                class="body-2 font-weight-bold black--text text-capitalize">
                {{ form.ticket_allotment_type }}
              </div>
            </div>

            <div
              class="d-flex align-center">
              <div
                class="body-2">
                Waktu Kunjung
              </div>

              <v-spacer />

              <div
                class="body-2 font-weight-bold black--text">
                {{ form.ticket_allotment_date | date }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text>
        <div
          class="body-1 font-weight-bold black--text mb-3">
          Harga
        </div>

        <div
          class="body-2 d-flex align-center text-capitalize">
          <div>
            <v-icon small color="#F38C35">mdi-rhombus-medium</v-icon>

            Tiket {{ form.ticket_allotment_type }} (x{{ form.total_ticket }})
          </div>

          <v-spacer />

          <div
            class="font-weight-bold black--text">
            {{ form.ticket_allotment_total_price | price }}
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <div
          class="body-1 font-weight-bold black--text mb-3">
          Add-Ons
        </div>

        <div
          v-for="(item, index) in form.addon_items"
          :key="index"
          class="body-2 d-flex align-center"
          :class="index < form.addon_items.length - 1 ? 'mb-2' : ''">
          <div>
            <v-icon small color="#F38C35">mdi-rhombus-medium</v-icon>

            {{ item.addon_title }} (x1)
          </div>

          <v-spacer />

          <div
            class="font-weight-bold black--text">
            {{ item.addon_price | price }}
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <ValidationObserver 
          ref="form">
          <v-form>
            <div
              class="body-1 font-weight-bold black--text mb-3">
              Punya Voucher?
            </div>

            <ValidationProvider 
              name="voucher_code" 
              v-slot="{ errors }">
              <v-text-field
                class="no_box mb-8"
                solo
                color="#F38C35"
                placeholder="Masukkan kode voucher disini"

                hide-details

                v-model="form.voucher_code"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>

            <div
              class="body-1 font-weight-bold black--text mb-3">
              Agen Benefit
            </div>

            <ValidationProvider 
              name="benefit_code" 
              v-slot="{ errors }">
              <v-text-field
                class="no_box"
                solo
                color="#F38C35"
                placeholder="Pilih benefit"

                hide-details

                v-model="form.benefit_code"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>
          </v-form>
        </ValidationObserver>
      </v-card-text>

      <v-card-text>
        <v-divider
          class="mb-4"
          style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />
          
        <div
          class="body-1 font-weight-bold black--text mb-3">
          Potongan Harga
        </div>

        <div
          class="body-2 d-flex align-center mb-2">
          <div>
            <v-icon small color="#F38C35">mdi-rhombus-medium</v-icon>

            Voucher
          </div>

          <v-spacer />

          <div
            class="font-weight-bold black--text">
            -
          </div>
        </div>

        <div
          class="body-2 d-flex align-center">
          <div>
            <v-icon small color="#F38C35">mdi-rhombus-medium</v-icon>

            Agen Benefit
          </div>

          <v-spacer />

          <div
            class="font-weight-bold black--text">
            -
          </div>
        </div>

        <v-divider
          class="mt-4"
          style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />
      </v-card-text>

      <v-card-text>
        <v-card
          color="#FFFFFF"
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
          <v-card-text
            class="body-2 d-flex align-center">
            <div
              class="color-purple">
              Total Pembayaran
            </div>

            <v-spacer />

            <div
              class="font-weight-bold black--text">
              {{ form.total_price | price }}
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text
        :class="!selected.bank ? 'pb-16 mb-8' : ''">
        <div
          class="body-1 font-weight-bold black--text mb-3">
          Metode Pembayaran
        </div>

        <v-card
          @click="dialog.bank = true"
          flat
          height="50"
          color="#F0F3F9"
          class="d-flex align-center"
          style="border-radius: 10px;">
          <v-card-text
            class="body-1 d-flex align-center pa-3">
            <div v-if="!selected.bank"
              class="color-purple"
              style="opacity: 60%;">
              Pilih metode Pembayaran
            </div>

            <div v-else
              class="color-purple d-flex align-center">
              <v-img 
                contain
                width="35"
                height="auto"
                alt="heha" 
                :src="selected.bank.icon"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <span
                class="ml-2">
                {{ selected.bank.title }}
              </span>
            </div>

            <v-spacer />

            <div>
              <v-icon color="#2B3F6C">mdi-chevron-right</v-icon>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text v-if="selected.bank"
        class="pb-16 mb-8">
        <div
          class="body-1 font-weight-bold black--text mb-3">
          Panduan bayar BCA Virtual Account
        </div>

        <v-expansion-panels 
          v-model="selected.guide"
          flat>
          <v-expansion-panel
            v-for="(item, index) in guide"
            :key="index"
            style="background-color: transparent;"
            :style="selected.guide !== index ? '' : 'box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;'">
            <v-expansion-panel-header
              color="#FFFFFF"
              class="body-2 font-weight-bold pa-4"
              :class="selected.guide !== index && index < guide.length - 1 ? 'mb-4' : ''"
              :style="selected.guide !== index ? 'box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;' : 'border-radius: 15px 15px 0 0;'">
              <div
                class="d-flex align-center">
                <v-icon small color="#F38C35">mdi-rhombus-medium</v-icon>
              
                <span
                  class="ml-1">
                  {{ item.title }}
                </span>
              </div>

              <template v-slot:actions>
                <v-icon
                  color="black">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content
              class="pa-0 pb-3"
              color="white"
              :style="selected.guide !== index ? '' : 'border-radius: 0 0 15px 15px;'">
              <v-divider 
                class="mx-4 mb-3"
                style="border-top: 1px solid #2B3F6C60; border-style: dashed;" />
              <ul
                style="list-style-type: '-';">
                <li
                  v-for="(item_desc, index_desc) in item.desc"
                  :key="index_desc"
                  class="line-text-second">
                  <span
                    class="body-2 ml-2"
                    v-html="item_desc">
                  </span>
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <div v-if="message.error.length > 0"
        class="text-center ma-4 pb-16 mb-8 d-flex align-center">
        <div
          class="error--text">
          <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>
          
          <span
            class="ml-1">
            {{ message.error }}
          </span>
        </div>
      </div>

      <v-card
        flat
        color="#f9f9f9"
        min-height="140"
        style="position: fixed;
              bottom: 0;
              width: 460px;
              z-index: 2;">
        <v-card-text
          class="pa-4">
          <v-btn
            :disabled="process.form || form.payment_method === ''"
            :loading="process.form"
            v-on:keyup.enter="save"
            @click="save"
            x-large
            block
            elevation="0"
            color="#2B3F6C"
            class="body-1 font-weight-bold white--text text-capitalize mb-4"
            style="border-radius: 10px;">
            Checkout
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog 
      v-model="dialog.bank"
      persistent
      width="1000">
      <v-card 
        class="border-radius pa-2">
        <v-card-title 
          class="d-flex align-center pa-3 pt-2">
          <div
            class="body-1 font-weight-bold">
            Virtual Account
          </div>

          <v-spacer />

          <div>
            <v-icon 
              @click="dialog.bank = false"
              size="26" 
              color="black">
              mdi-close
            </v-icon>
          </div>
        </v-card-title>

        <v-card-text
          class="pa-0 px-3">
          <v-list 
            flat 
            dense
            color="transparent">
            <v-list-item
              v-for="(item, index) in bank"
              :key="index"
              @click="selected.bank = item; form.payment_method = item.value; dialog.bank = false;"
              :class="index < bank.length - 1 ? 'mb-4' : 'mb-2'"
              class="d-flex align-center pa-2 px-3"
              style="border: 1px solid #2B3F6C40; border-radius: 10px;">
              <v-list-item-avatar
                tile
                size="40"
                class="ma-0 mr-3">
                <v-img 
                  contain
                  alt="heha" 
                  :src="item.icon"
                  class="ma-auto"
                  style="border-radius: 10px;">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        color="#F38C35">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <div
                  class="body-2 ">
                  {{ item.title }}
                </div>
              </v-list-item-content>

              <v-list-item-action
                class="my-0">
                <v-icon size="26" color="#F38C35">mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      dialog: {
        type: Object,
        default: {}
      },
      selected: {
        type: Object,
        default: {}
      },
      bank: {
        type: Array,
        default: []
      },
      guide: {
        type: Array,
        default: []
      },
      form: {
        type: Object,
        default: {}
      },
      process: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      this.counting()
    },
    methods: {
      counting () {
        let price_addons = 0

        if (this.form.addon_items.length > 0) {
          this.form.addon_items.forEach(function(item, index) {
            price_addons += item.addon_price
          })
        }

        this.form.total_price = parseInt(this.form.ticket_allotment_total_price) + parseInt(price_addons)
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.PREFIX_PROXY}/checkout/ticket`, this.form)
          .then((response) => {

            if (response.code === 200) {
              this.$router.push({ path: `/reservation/qrcode/${this.$route.params.slug}` })
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>