<template>
  <div>
    <v-card
      flat
      color="transparent"
      style="border-radius: 0px;">
      <v-img
        contain
        width="100%"
        height="auto"
        alt="heha" 
        gradient="to left, rgba(0, 0, 0, .1), rgba(0, 0, 0, .7)"
        :src="require('@/assets/image/example/bg_gallery.png')"
        class="ma-auto d-flex align-center">
        <template v-slot:placeholder>
          <v-row
            class="fill-height"
            align="center"
            justify="center">
            <v-progress-circular 
              indeterminate 
              color="#F38C35">
            </v-progress-circular>
          </v-row>
        </template>

        <div
          class="text-h5 font-weight-bold white--text text-center">
          Kontak Kami
        </div>
      </v-img>
    </v-card>
  </div>
</template>

<script>
  export default {
    data () {
      return {

      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>