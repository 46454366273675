<template>
  <div class="fill-height py-14">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-card
            flat
            color="transparent">
            <v-card-title
              class="d-flex align-center pa-3"
              style="position: fixed;
                    top: 100 px;
                    width: 460px;
                    background: #f9f9f9;
                    z-index: 2;">
              <v-btn
                @click="$router.go(-1)"
                icon 
                width="36"
                height="36">
                <v-icon color="#2B3F6C">mdi-arrow-left</v-icon>
              </v-btn>

              <span
                class="body-1 font-weight-bold color-purple ml-2">
                Testimoni
              </span>
            </v-card-title>

            <div
              class="pt-16">
              <v-row
                class="pt-4">
                <v-col
                  v-for="(item, index) in process.load ? 6 : list"
                  :key="index"
                  cols="12"
                  class="py-0">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.load"
                    type="image, article">
                    <div>
                      <div v-if="!process.load && list.length > 0"
                        class="px-4 pb-4">
                        <v-card
                          class="pa-1 pt-0"
                          color="#FFFFFF"
                          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
                          <v-card-text
                            class="pa-3">
                            <v-list 
                              flat 
                              color="transparent"
                              class="pt-1 pb-3">
                              <v-list-item
                                class="pa-0">
                                <v-list-item-avatar
                                  color="#F0F3F9"
                                  size="50"
                                  class="border-radius my-0">
                                  <v-img 
                                    contain
                                    width="26"
                                    height="26"
                                    alt="heha" 
                                    :src="item.testimony_user_image === '' ? require('@/assets/icon/user.png') : item.testimony_user_image"
                                    class="ma-auto"
                                    style="border-radius: 10px;">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          color="#F38C35">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content
                                  class="pa-0">
                                  <v-list-item-title 
                                    class="body-1 font-weight-bold mb-2">
                                    {{ item.testimony_user_name }}
                                  </v-list-item-title>

                                  <v-list-item-subtitle>
                                    {{ item.testimony_date | date }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>

                            <div
                              class="body-2 line-text-first">
                              "{{ item.testimony_content }}"
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </div>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        list: [],
        pagination: {},
        process: {
          load: false
        },
        // SEO
        content: {
          url: '/testimony',
          title: 'Testimoni',
          description: 'Testimoni HeHa Sky View',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true

        await this.$axios.$get(`${process.env.PREFIX_PROXY}/testimony`)
        .then((response) => {
          if (response.code === 200) {
            this.process.load = false

            this.list = response.result.data
            this.pagination = response.result.meta
          } 
        })
      }
    }
  }
</script>