<template>
  <v-app>
    <MobileHeader
      class="mobile" />
    
    <MobileFooter 
      class="mobile"
      v-if="!loader" />

    <DesktopHeader
      class="desktop" />

    <v-sheet 
      light
      class="desktop"
      style="background: #f9f9f9;"
      min-height="100%">
      <Loader v-if="loader" />
      
      <nuxt v-else />
    </v-sheet>
    
    <DesktopFooter 
      class="desktop"
      v-if="!loader" />

    <v-fab-transition
      class="desktop">
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        elevation="3"
        fab
        dark
        fixed
        bottom
        right
        color="orange"
        @click="$vuetify.goTo('#app', { duration: 500, offset: 0 })">
        <v-icon large>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>        
  </v-app>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined

  import Loader from "~/components/Loader.vue"

  import MobileHeader from "~/components/public/mobile/Header.vue"
  import DesktopHeader from "~/components/public/desktop/Header.vue"
  import MobileFooter from "~/components/public/mobile/Footer.vue"
  import DesktopFooter from "~/components/public/desktop/Footer.vue"
  
  import firebase from "firebase/app"
  import "firebase/messaging"
  export default {
    data () {
      return {
        loader: true,
        fab: false
      }
    },
    components: {
      Loader,
      MobileHeader,
      MobileFooter,
      DesktopHeader,
      DesktopFooter
    },
    mounted () {
      this.loader = false
    },
    beforeCreate() {
      this.loader = true
    },
    created() {
      this.requestPermission()
    },
    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return

        const top = window.pageYOffset || e.target.scrollTop || 0

        this.fab = top > window.innerHeight-100
      },
      requestPermission () {
        try {
          firebase
            .messaging()
            .requestPermission()
            .then(() => {
              console.log("Notification permission granted")

              firebase
                .messaging()
                .getToken()
                .then((token) => {
                  window.console.log(token)

                  this.$store.commit('member/auth/setFcm', token)
                  Cookie.set('fcm', token)

                  this.receiveMessage()
                })
            })
            .catch((err) => {
              console.log("Unable to get token ", err)
            })
        } catch (e) {
          console.log(e)
        }
      },
      receiveMessage () {
        try {
          firebase
            .messaging()
            .onMessage((payload) => {
              console.log("payload", payload)
            })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
