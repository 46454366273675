<template>
  <div>
    <v-card
      flat
      height="calc(100vh - 270px)"
      color="transparent">
      <v-card-text
        class="px-0 mt-6">
        <v-card
          color="#FFFFFF"
          class="pa-3"
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
          <v-card-title
            class="pa-0 pb-3">
            <div
              class="d-flex align-center">
              <v-img 
                contain
                width="40"
                height="40"
                alt="heha" 
                :src="require('@/assets/image/bank/BCA.png')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      color="#F38C35">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <span
                class="body-2 ml-2">
                BCA
              </span>
            </div>
          </v-card-title>

          <v-card
            flat
            color="#F0F3F9"
            style="border-radius: 15px;">
            <v-card-text>
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold color-purple">
                  1234567890
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold color-default ml-2">
                  copy
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-card-text>

      <v-card-text
        class="px-0 pb-2">
        <v-card
          color="#FFFFFF"
          class="pa-3"
          style="box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; border-radius: 15px;">
          <v-card-title
            class="body-2 pa-0 pb-4">
            Total Transfer
          </v-card-title>

          <v-card
            flat
            color="#F0F3F9"
            style="border-radius: 15px;">
            <v-card-text>
              <div
                class="d-flex align-center">
                <div
                  class="body-1 font-weight-bold color-purple">
                  Rp 40.000
                </div>

                <v-spacer />

                <div
                  class="body-2 font-weight-bold color-default ml-2">
                  copy
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card>
      </v-card-text>

      <v-card-text
        class="px-0 pb-16 mb-8">
        <div
          class="body-1 font-weight-bold black--text mb-3">
          Penting
        </div>

        <div
          class="body-2 line-text-first">
          Detail terkait flow ketika pengguna sudah melakukan pembayaran reservasi tiket, pengguna akan mendapatkan pesan yang dikirim ke nomor whatsapp berisikan link untuk melihat detail pembayaran dan link untuk melihat tiket yang sudah di booking.
        </div>
      </v-card-text>

      <div
        style="position: absolute;
              bottom: 0;
              max-width: inherit;
              width: 100%;">
        <v-btn
          to="/reservation/qrcode/tiketreguler"
          x-large
          block
          elevation="0"
          color="#2B3F6C"
          class="body-1 font-weight-bold white--text text-capitalize"
          style="border-radius: 10px;">
          Cek Status Pembayaran
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      }
    },
    mounted () {
      
    },
    methods: {
      
    }
  }
</script>